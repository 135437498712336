import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ContactContainer } from "./contact.style";
import Navbar from "../../components/shared-components/navbar/Navbar";
import Footer from "../../components/shared-components/footer/Footer";
import Main from "../../components/contact-components/main/Main";
import contact from "../../assets/images/contact.png";
import Sub from "../../components/contact-components/sub/Sub";
import group from "../../assets/images/group.png";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <ContactContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Petrong Software Solutions - Contact"
        />
        <meta
          name="keywords"
          content="Petrong, Petrong Software, Petrong Software Solutions"
        />
        <meta name="author" content="Petrong Software Company" />
        <meta name="robots" content="index,follow" />
        <title>Petrong - Contact</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
        />
      </Helmet>
      <Navbar className="contact-navbar" isBlack="true" />
      <img src={contact} alt="contact" className="contact-img" />
      <img src={group} alt="group" className="group" />
      <Main />
      <Sub />
      <Footer />
    </ContactContainer>
  );
};

export default Contact;
