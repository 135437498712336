import styled from "styled-components"
import { Box } from "@mui/material"

export const MainContainer = styled(Box)`
width: 100%;
padding: 2rem 0 4rem;
position: relative;
`

export const MainContent = styled(Box)`
  width: 85%;
  margin: 0 auto;
  box-sizing: border-box;
  padding-right: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: 922px) {
    width: 90%;
    padding-right: 0;
  }
  @media only screen and (max-width: 587px) {
    justify-content: center;
  }
`;

export const ContentChild = styled(Box)`
  width: 30%;
  margin-top: 2rem;
  img {
    width: 100%;
  }
  a {
    text-decoration: none;
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 30px;
    color: #00d285;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
    color: #000;
  }
  .MuiButton-root.MuiButton-contained {
    background-color: #00d285;
    text-transform: none;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    margin-top: 1rem;
    border-radius: 3px;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 1400px) {
    width: 31%;
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 922px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.85rem;
    }
  }
  @media only screen and (max-width: 769px) {
    width: 45%;
    margin-bottom: 1.5rem;
  }
  @media only screen and (max-width: 587px) {
    width: 90%;
  }
  @media only screen and (max-width: 380px) {
    width: 95%;
  }
`;
export const Title = styled(Box)`
  width: 85%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  &::after {
    content: "";
    width: 25%;
    height: 1px;
    background-color: #00d285;
    position: absolute;
    left: 0;
    top: 50%;
  }

  &::before {
    content: "";
    width: 25%;
    height: 1px;
    background-color: #00d285;
    position: absolute;
    right: 0;
    top: 50%;
  }
  @media only screen and (max-width: 1400px) {
    &::after {
      width: 18%;
    }
    &::before {
      width: 18%;
    }
  }
  @media only screen and (max-width: 1214px) {
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 1094px) {
    width: 100%;
  }

  @media only screen and (max-width: 940px) {
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 12px;
    }
  }
`;
export const TextBox = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  padding-left: 0.4rem;
  margin-bottom: 1rem;
  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 30px;
    color: #00d285;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
  }
  .MuiButton-root.MuiButton-contained {
    background-color: #00d285;
    text-transform: none;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 1400px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 1094px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 922px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.85rem;
    }
  }
`;