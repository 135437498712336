import React from 'react'
import { HeaderContainer } from './header.style'
import blogBg from '../../../assets/images/blog-bg.png'

const Header = () => {
  return (
    <HeaderContainer>
        <img src={blogBg} alt="blog-bg" />
    </HeaderContainer>
  )
}

export default Header