import styled from "styled-components"
import { Box } from "@mui/material"

export const SubContainer = styled(Box)`
width: 100%;
box-sizing: border-box;
padding: 2rem 1rem 0 2rem;
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 2rem;
@media only screen and (max-width: 780px){
  align-items: flex-start;
}
@media only screen and (max-width: 587px){
  flex-wrap: wrap-reverse;
  margin-top: 2rem;
}
`

export const SubLeft = styled(Box)`
  width: 40%;
  padding-left: 3rem;
  @media only screen and (max-width: 857px) {
    padding-left: 1rem;
  }
  @media only screen and (max-width: 816px) {
    width: 47%;
  }
  @media only screen and (max-width: 780px){
    padding-left: 0;
  }
  @media only screen and (max-width: 587px){
    width: 90%;
  }
  @media only screen and (max-width: 320px){
    width: 95%;
  }
`;
export const Title = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .line {
    width: 65%;
    height: 2px;
    background-color: #00d285;
    margin-left: 10px;
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 30px;
    text-align: justify;
  }
  @media only screen and (max-width: 1112px){
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.85rem;
    }
    .line {
      width: 55%;
    }
  }
  @media only screen and (max-width: 654px){
    .MuiTypography-root.MuiTypography-h6{
      font-size: 0.75rem;
    }
    .line{
      width: 52%;
    }
  }
  @media only screen and (max-width: 312px){
    .MuiTypography-root.MuiTypography-h6{
      font-size: 0.7rem;
    }
    .line{
      width: 45%;
    }
  }
`;
export const TextBox = styled(Box)`
  width: 92%;
  margin: 1rem 0;
  a {
    text-decoration: none;
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 30px;
    color: #00d285;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
  }
  .MuiButton-root.MuiButton-contained {
    background-color: #00d285;
    text-transform: none;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    margin-top: 1rem;
  }
  @media only screen and (max-width: 1133px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 955px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.85rem;
    }
  }
  @media only screen and (max-width: 816px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.75rem;
    }
  }
  @media only screen and (max-width: 740px) {
    width: 100%;
  }
`;
export const TextBoxB = styled(Box)`
  width: 70%;
  margin: 1rem 0;
  a {
    text-decoration: none;
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 30px;
    color: #00d285;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 29px;
  }
  .MuiButton-root.MuiButton-contained {
    background-color: #00d285;
    text-transform: none;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 994px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 834px) {
    width: 85%;
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 740px) {
    width: 95%;
  }
`;
export const SubRight = styled(Box)`
  width: 55%;
  img {
    width: 100%;
  }
  @media only screen and (max-width: 857px) {
    width: 50%;
  }
  @media only screen and (max-width: 587px) {
    width: 90%;
  }
`;