import styled from "styled-components";
import { Box } from "@mui/material";

export const HeaderContainer = styled(Box)`
  width: 100%;
  height: 95vh;
  background: #ebfaf4;
  box-sizing: border-box;
  padding: 3.5rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  .contact-img {
    position: absolute;
    z-index: 0;
    top: 0%;
    left: 18.5%;
  }
@media only screen and (max-width: 1240px){
  .contact-img {
    top: -7%;
    left: 14%;
  }
}
  @media only screen and (max-width: 1140px) {
    padding: 3.5rem 2rem 3.5rem 3.5rem;
    .contact-img{
      left: 9%;
    }
  }
  @media only screen and (max-width: 970px) {
    .contact-img{
      left: 4%;
    }
  }
  @media only screen and (max-width: 840px) {
    height: 80vh;
    .contact-img{
      top: -25%;
    }
  }
  @media only screen and (max-width: 760px) {
    height: 70vh;
    .contact-img{
      display: none;
    }
  }
  @media only screen and (max-width: 687px) {
    
    justify-content: flex-start;
    height: 50vh;
  }
  @media only screen and (max-width: 470px) {
    height: 45vh;
    padding: 0 0.5rem;
  }
  /* @media only screen and (max-width: 305px) {
    padding: 3rem 0.5rem;
  } */
`;

export const HeaderLeft = styled(Box)`
  position: relative;
  z-index: 2;
  width: 45%;
  height: 100%;
  img {
    width: 100%;
    height: 80%;
  }
  @media only screen and (max-width: 1290px) {
    width: 45%;
  }
  @media only screen and (max-width: 1180px) {
    width: 50%;
  }
  @media only screen and (max-width: 1084px) {
    width: 50%;
    img {
      height: 90%;
    }
  }

  @media only screen and (max-width: 940px) {
    width: 45%;
    img {
      height: 80%;
    }
  }

  @media only screen and (max-width: 840px) {
    img {
      height: 70%;
    }
  }
  @media only screen and (max-width: 687px) {
    display: none;
  }
`;

export const HeaderRight = styled(Box)`
  box-sizing: border-box;
  width: 60%;
  padding: 2.9rem 4rem 2.9rem 3rem;
  @media only screen and (max-width: 1080px) {
    padding: 2.9rem 1.2rem;
  }
  @media only screen and (max-width: 729px) {
    padding: 2.9rem 0 2.9rem 2rem;
  }
  @media only screen and (max-width: 687px) {
    width: 80%;
    padding: 2.9rem 0 2.9rem 1rem;
  }
  @media only screen and (max-width: 470px) {
    width: 95%;
  }
  @media only screen and (max-width: 366px) {
    padding: 2.9rem 0;
  }
`;

export const SubBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
  .ellipse {
    position: absolute;
    right: 39%;
    bottom: -50%;
  }
  .line {
    width: 53px;
    height: 3px;
    background-color: #00d285;
    margin-right: 10px;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 3rem;
    line-height: 30px;
    text-align: justify;
  }

  @media only screen and (min-width: 1240px) and (max-width: 1440px) {
    .ellipse {
      right: 25%;
    }
  }
  @media only screen and (max-width: 1239px) {
    .ellipse {
      right: 15%;
    }
  }
  @media only screen and (max-width: 1180px) {
    .ellipse {
      right: 20%;
    }
  }
  @media only screen and (max-width: 1170px) {
    .ellipse {
      right: 15%;
    }
  }
  @media only screen and (max-width: 1084px) {
    .ellipse {
      right: 10%;
    }
  }
  @media only screen and (max-width: 1039px) {
    .ellipse {
      right: 5%;
    }
  }
  @media only screen and (max-width: 999px) {
    .ellipse {
      right: 0%;
    }
  }
  @media only screen and (max-width: 773px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }
  @media only screen and (max-width: 687px) {
    .ellipse {
      right: 15%;
    }
  }
  @media only screen and (max-width: 685px) {
    .ellipse{
      width: 26%;
      right: 40%;
    }
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }
  /* @media only screen and (max-width: 587px) {
    .ellipse {
      right: 4%;
    }
  } */
  @media only screen and (max-width: 527px) {
    .ellipse {
      right: 25%;
    }
  }
  @media only screen and (max-width: 367px) {
    .ellipse {
      width: 95px;
      height: 95px;
      right: 17%;
    }
  }
  @media only screen and (max-width: 331px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
    }
  }
`;
