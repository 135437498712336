import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoreIcon from "@mui/icons-material/Menu";
import SearchIcon from '@mui/icons-material/Search';
import {
  ButtonBox,
  MenuBar,
  MenuBox,
  MenuToolBar,
  NavButton,
  SearchBox,
} from "./nav.style";
import { Button, TextField } from "@mui/material";

const Nav = () => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  // const [anchorEl, setAnchorEl] = useState(null);

  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <NavButton variant="text">Home</NavButton>
      </MenuItem>
      <MenuItem>
        <NavButton variant="text">UI/UX Design</NavButton>
      </MenuItem>
      <MenuItem>
        <NavButton variant="text">World Wide Web</NavButton>
      </MenuItem>
      <MenuItem>
        <NavButton variant="text">Mobile App World</NavButton>
      </MenuItem>
      <MenuItem>
        <NavButton variant="text">Digital Marketing</NavButton>
      </MenuItem>
    </Menu>
  );
  return (
    <MenuBox sx={{ flexGrow: 1 }}>
      <MenuBar>
        <MenuToolBar>
          <ButtonBox>
            <NavButton variant="text" className="sign">
              Home
            </NavButton>
            <NavButton variant="text" className="sign">
              UI/UX Design
            </NavButton>
            <NavButton variant="text" className="sign">
              World Wide Web
            </NavButton>
            <NavButton variant="text" className="sign">
              Mobile App World
            </NavButton>
            <NavButton variant="text" className="sign">
              Digital Marketing
            </NavButton>
          </ButtonBox>
          <Box
            sx={{ display: { xs: "flex", md: "none" } }}
            style={{ marginRight: "2rem", width: "15%" }}
            className="menu-div"
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon fontSize="large" />
            </IconButton>
          </Box>
          <SearchBox>
            <TextField placeholder="Search..."></TextField>
            <Button variant="contained"><SearchIcon /></Button>
          </SearchBox>
        </MenuToolBar>
      </MenuBar>
      {renderMobileMenu}
    </MenuBox>
  );
};

export default Nav;
