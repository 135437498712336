import styled from "styled-components";
import { Box } from "@mui/material";

export const BodyContainer = styled(Box)`
  width: 100%;
  height: 220vh;
  margin-top: -2rem;
  overflow: hidden;
  @media only screen and (max-width: 587px) {
    height: unset;
    padding-top: 1.5rem;
  }
`;

export const BodyChild = styled(Box)`
  width: 90%;
  height: 28%;
  margin: 0 auto 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  .chat {
    position: absolute;
    right: 4%;
    top: 4%;
  }
  @media only screen and (max-width: 1112px) {
    .chat {
      width: 5%;
    }
  }
  @media only screen and (max-width: 899px) {
    width: 95%;
  }
  @media only screen and (max-width: 587px) {
    height: 28%;
    flex-wrap: wrap-reverse;
    justify-content: center;
    margin: 0 auto 2.5rem;
    &:not(:nth-child(2)) {
      flex-wrap: wrap;
    }
  }
`;

export const BodyLeft = styled(Box)`
  width: 50%;
  padding-left: 3rem;
  padding-top: 4rem;

  .MuiTypography-root.MuiTypography-body2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;
    text-align: justify;
    color: #7b7b7b;
    margin-bottom: 0.7rem;
  }

  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    color: #000d2f;
    margin-bottom: 0.8rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    text-align: justify;
  }
  .second {
    font-size: 1.1rem !important;
    line-height: 30px !important;
  }

  .MuiButton-root.MuiButton-contained {
    background-color: #00d285;
    text-transform: none;
    font-weight: 500;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    margin-top: 1rem;
  }

  @media only screen and (max-width: 1153px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 1rem;
      line-height: 20px;
    }
  }
  @media only screen and (max-width: 998px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.5rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.9rem;
    }
    .second {
      font-size: 0.9rem !important;
    }
    ul {
      li {
        font-size: 0.9rem;
      }
    }
  }
  @media only screen and (max-width: 899px) {
    padding-left: 1.5rem;
  }
  @media only screen and (max-width: 800px) {
    padding-left: 0;
    .MuiTypography-root.MuiTypography-h4 {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 726px) {
    width: 55%;
    padding-top: 2rem;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 587px) {
    width: 85%;
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 533px) {
    .MuiTypogrpahy-root.MuiTypography-body2 {
      font-size: 0.9rem;
    }
  }

  @media only screen and (max-width: 420px) {
    width: 95%;
  }
`;
export const BodyRight = styled(Box)`
  width: 40%;
  height: 100%;
  img {
    height: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    width: 44%;
  }
  @media only screen and (max-width: 726px) {
    width: 40%;
    img {
      height: 90%;
    }
  }
  @media only screen and (max-width: 587px) {
    width: 80%;
    height: 45%;
    img {
      height: 85%;
    }
  }

  @media only screen and (max-width: 420px) {
    width: 90%;
    img {
      height: auto;
    }
  }
`;

export const Extra = styled(Box)`
  width: 50%;
  text-align: center;
  margin: 5rem auto 3rem;

  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 2rem;
    line-height: 20px;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 29px;
  }
  a {
    text-decoration: none;

    color: white;
    .MuiButton-root.MuiButton-contained {
      margin-top: 3rem;
      text-transform: none;
      background-color: black;
      font-weight: 500;
      font-size: 12px;
      padding: 0.4rem 1.5rem;
    }
  }

  @media only screen and (max-width: 1298px) {
    width: 65%;
  }

  @media only screen and (max-width: 998px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1.5rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.9rem;
    }
  }
  @media only screen and (max-width: 899px) {
    width: 75%;
  }
  @media only screen and (max-width: 779px) {
    width: 85%;
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1.2rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 700px) {
    margin: 6rem auto 2rem;

    .MuiButton-root.MuiButton-contained {
      margin-top: 2rem;
    }
  }
  @media only screen and (max-width: 611px) {
    width: 95%;
    .MuiButton-root.MuiButton-contained {
      margin-top: 1rem;
    }
  }
  @media only screen and (max-width: 380px) {
    width: 98%;
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.7rem;
    }
  }
`;
