import styled from "styled-components";
import { Box } from "@mui/material";

export const MainContainer = styled(Box)`
  width: 100%;
  height: 95vh;
  background: #ebfaf4;
  box-sizing: border-box;
  padding: 3.5rem;
  display: flex;

  @media only screen and (max-width: 1140px) {
    padding: 3.5rem 2rem 3.5rem 3.5rem;
  }
  @media only screen and (max-width: 840px) {
    height: 80vh;
  }
  @media only screen and (max-width: 760px) {
    height: 70vh;
  }
  @media only screen and (max-width: 687px) {
    flex-direction: column-reverse;
    height: 60vh;
  }
  @media only screen and (max-width: 470px) {
    height: 65vh;
    padding: 3rem 1.5rem;
  }
  @media only screen and (max-width: 305px) {
    padding: 3rem 0.5rem;
  }
`;

export const MainLeft = styled(Box)`
  position: relative;
  z-index: 2;
  width: 40%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 1290px) {
    width: 45%;
  }
  @media only screen and (max-width: 1180px) {
    width: 50%;
  }
  @media only screen and (max-width: 1084px) {
    width: 50%;
    img {
      height: 90%;
    }
  }

  @media only screen and (max-width: 940px) {
    width: 45%;
    img {
      height: 85%;
    }
  }

  /* @media only screen and (max-width: 840px) {
    img {
      height: 70%;
    }
  } */
  @media only screen and (max-width: 687px) {
    display: none;
  }
`;

export const MainRight = styled(Box)`
  box-sizing: border-box;
  width: 60%;
  height: 100%;
  padding: 2.9rem 4rem 2.9rem 3rem;
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 54px;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 25px;
  }

  @media only screen and (max-width: 940px) {
    padding: 2.9rem 2rem;
  }
  @media only screen and (max-width: 840px) {
    padding: 0.9rem 2rem;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
      line-height: 30px;
    }
  }
  /* @media only screen and (max-width: 729px) {
    padding: 2.9rem 0 2.9rem 2rem;
  } */
  @media only screen and (max-width: 687px) {
    width: 85%;
    padding: 0.9rem 1rem;
  }
  @media only screen and (max-width: 470px) {
    width: 90%;
  }
  @media only screen and (max-width: 420px) {
    width: 95%;
  }
  @media only screen and (max-width: 366px) {
    padding: 2.9rem 0;
  }
`;
export const SubBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  position: relative;
  .ellipse {
    position: absolute;
    right: 12%;
  }
  .line {
    width: 53px;
    height: 3px;
    background-color: #00d285;
    margin-right: 10px;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 3rem;
    line-height: 40px;
    text-align: start;
    margin-bottom: 0;
    z-index: 4;
  }

  @media only screen and (max-width: 1340px) {
    .ellipse {
      right: -5%;
    }
  }
  @media only screen and (max-width: 1299px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2.5rem;
    }
    .ellipse {
      right: -1%;
    }
  }
  /* @media only screen and (max-width: 1180px) {
    .ellipse {
      right: 20%;
    }
  } */
  @media only screen and (max-width: 1170px) {
    .ellipse {
      right: -7%;
    }
  }
  @media only screen and (max-width: 1084px) {
    .ellipse {
      right: 10%;
    }
  }
  @media only screen and (max-width: 1039px) {
    .ellipse {
      right: 5%;
    }
  }
  @media only screen and (max-width: 999px) {
    margin-bottom: 1rem;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2.1rem;
      line-height: 30px;
    }
    .ellipse {
      width: 140px;
      height: 140px;
      right: 0%;
    }
  }
  @media only screen and (max-width: 818px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }
  @media only screen and (max-width: 750px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.5rem;
    }
  }

  @media only screen and (max-width: 687px) {
    .ellipse {
      right: 15%;
    }
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.4rem;
    }
  }

  @media only screen and (max-width: 587px) {
    .ellipse {
      right: 4%;
    }
  }
  @media only screen and (max-width: 527px) {
    .ellipse {
      right: -17%;
    }
  }
  @media only screen and (max-width: 420px) {
    .MuiTypography-root.MuiTypography-h4{
      line-height: 25px;
    }
  }
  @media only screen and (max-width: 367px) {
    .ellipse {
     display: none;
    }
  }
  @media only screen and (max-width: 331px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.7rem;
    }
  }
`;
