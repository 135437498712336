import styled from "styled-components"
import { Box } from "@mui/material"
import bg from "../../../assets/images/product.png"

export const HeaderContainer = styled(Box)`
  width: 100%;
  height: 65vh;
  background: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* overflow: hidden; */
  .contact-img {
    position: absolute;
    z-index: 0;
    top: 0%;
    left: 18.5%;
  }
  .group{
    position: absolute;
    left: 7%;
    bottom: -12%;
  }
  @media only screen and (max-width: 1240px) {
    .contact-img {
        left: 12%;
    }
  }
  @media only screen and (max-width: 980px) {
    .contact-img {
        left: 5%;
    }
    .group{
        bottom: -15%;
    }
  }
  @media only screen and (max-width: 899px) {
    .contact-img {
        display: none;
    }
    .group{
        bottom: -18%;
    }
  }
  @media only screen and (max-width: 587px){
      margin-bottom: 12rem;
  }
`;

  export const HeaderContent = styled(Box)`
    width: 50%;
    text-align: center;
    .MuiTypography-root {
      font-family: "Montserrat", sans-serif;
    }
    .MuiTypography-root.MuiTypography-h4 {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 3rem;
      line-height: 30px;
      text-align: center;
      margin-bottom: 1.5rem;
    }

    .MuiTypography-root.MuiTypography-body1 {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 38px;
      text-align: center;
    }

    @media only screen and (max-width: 1305px) {
      .MuiTypography-root.MuiTypography-h4{
        font-size: 2.5rem;
      }
    }
    @media only screen and (max-width: 1153px) {
      width: 65%;
      margin-top: -2rem;
    }
    @media only screen and (max-width: 887px) {
      width: 70%;
      .MuiTypography-root.MuiTypography-h4{
        font-size: 2rem;
      }
      .MuiTypography-root.MuiTypography-body1 {
        font-size: 1rem;
      }
    }
    @media only screen and (max-width: 749px) {
      width: 80%;
      .MuiTypography-root.MuiTypography-body1 {
        font-size: 0.9rem;
      }
    }
    @media only screen and (max-width: 685px) {
      .MuiTypography-root.MuiTypography-h4 {
        font-size: 1.2rem;
      }
    }
    @media only screen and (max-width: 575px) {
      width: 90%;
      .MuiTypography-root.MuiTypography-h6 {
        margin-bottom: 1.2rem;
      }
      .MuiTypography-root.MuiTypography-body1 {
        font-size: 0.8rem;
      }
    }
    @media only screen and (max-width: 440px) {
      margin-top: -4rem;
      .MuiTypography-root.MuiTypography-h6 {
        font-size: 1.4rem;
      }
    }
    @media only screen and (max-width: 360px) {
      width: 98%;
      .MuiTypography-root.MuiTypography-h6 {
        font-size: 1.2rem;
      }
      .MuiTypography-root.MuiTypography-body1 {
        font-size: 0.8rem;
        line-height: 28px;
      }
    }
  `;