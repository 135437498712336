import { Button, Typography } from "@mui/material";
import React from "react";
// import { Link } from 'react-router-dom'
import {
  BodyContainer,
  BodyContent,
  ContentLeft,
  ContentRight,
  Info,
  LinkBox,
  Post,
  TextBox,
  Title,
} from "./body.style";
import author from "../../../assets/images/author.png";
import blogA from "../../../assets/images/blogA.png";
import blogB from "../../../assets/images/blogB.png";
import blogC from "../../../assets/images/blogC.png";
import authorA from "../../../assets/images/authorA.png";
import authorB from "../../../assets/images/authorB.png";
const Body = () => {
  return (
    <BodyContainer>
      <Title>
        <LinkBox>
          <Button variant="text">Inspiration</Button>
          <Button variant="text">Design</Button>
        </LinkBox>
        <Typography variant="h2">
          8 UX/UI Trends That Will Dominate The Web Design World in 2022
        </Typography>
      </Title>
      <Info>
        <img src={author} alt="author" />
        <Typography variant="body1">By Prime Leonard</Typography>
      </Info>
      <BodyContent>
        <ContentLeft>
          <img src={blogA} alt="blogA" />
          <Typography variant="body1">
            2021 is slowly reaching its end. This year’s UI design trends and UX
            trends will gently move aside to let next year’s winners take their
            place.
          </Typography>
          <Typography variant="body1">
            Quality design is paramount when it comes to creating an attractive
            website that generates traffic and converts visitors into customers.
            Whether your brand offers a service or product, consumers will judge
            your company based not only on the quality of your product but also
            on the quality of your design. Poorly designed sites are often
            ignored, regardless of how beneficial they are. The only way to
            acquire customers and distinguish yourself from the competition is
            by improving your UX/UI design.
          </Typography>
          <Typography variant="body1">
            And don’t get me wrong, you might have one of the top user interface
            designs of the past, but if you don’t update it, you’ll most likely
            end up with an old website.
          </Typography>
        </ContentLeft>
        <ContentRight>
          <Post>
            <img src={blogC} alt="blogC" className="blog" />
            <TextBox>
              <Typography variant="body2">Design</Typography>
              <Typography variant="h4">
                Designing an Effective Splash Screen: Best Practices, Tips and
                Examples
              </Typography>
              <Info>
                <img src={authorB} alt="authorB" />
                <Typography variant="body1">By Rasheed</Typography>
              </Info>
            </TextBox>
          </Post>
          <Post>
            <img src={blogB} alt="blogC" className="blog" />
            <TextBox>
              <Typography variant="body2">Design</Typography>
              <Typography variant="h4">
                How To Design The Perfect Hero Image: Guide, Practical Tips and
                Examples
              </Typography>
              <Info>
                <img src={authorA} alt="authorB" />
                <Typography variant="body1">By Prime Leonard</Typography>
              </Info>
            </TextBox>
          </Post>
        </ContentRight>
      </BodyContent>
    </BodyContainer>
  );
};

export default Body;
