import React from "react";
import {
  PartnershipContainer,
  PartnershipChild,
  PartnershipBoxA,
  PartnershipBoxB,
} from "./partnership.style";
import partnership from "../../../assets/images/partnership.jpg";
import { Button, Typography } from "@mui/material";
import partner from "../../../assets/images/partner.png";
import { Link } from "react-router-dom";
const Partnership = () => {
  return (
    <PartnershipContainer>
      <img src={partner} alt="partner" className="partner" />
      <PartnershipChild>
        <PartnershipBoxA>
          <img src={partnership} alt="partnership" />
        </PartnershipBoxA>
        <PartnershipBoxB>
          <Typography variant="h4" className='header'>Partnership for agencies</Typography>
          <Typography variant="body1">
            A reliable software development partner that delivers solutions to your business
            challenges and stays by your side in the long term. Having the whole
            spectrum of software development expertise, we are here to take care of
            your projects while making it possible for you to focus on growing
            your own business.
          </Typography>
          <Link to="/partnership">
            <Button variant="contained">Partnership Details</Button>
          </Link>
        </PartnershipBoxB>
      </PartnershipChild>
    </PartnershipContainer>
  );
};

export default Partnership;
