import styled from "styled-components";
import { Box } from "@mui/material";
import bg from "../../../assets/images/header-bg.jpg";

export const HeaderContainer = styled(Box)`
  width: 100%;
  height: 83vh;
  margin-bottom: 8rem;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  .chat {
    position: absolute;
    bottom: -20%;
    right: 5%;
    width: 5%;
    cursor: pointer;
  }
  @media only screen and (max-width: 1100px) {
    .chat {
      width: 7%;
    }
  }
  @media only screen and (max-width: 587px) {
    height: 110vh;
    .chat {
      bottom: -13%;
    }
  }
  @media only screen and (max-width: 480px) {
    .chat {
      width: 11%;
    }
  }
`;

export const HeaderChild = styled(Box)`
  width: 90%;
  height: 70%;
  box-sizing: border-box;
  margin: 0 auto 1rem;
  padding-left: 2rem;
  display: flex;
  justify-content: space-between;
 
  
  @media only screen and (max-width: 1084px) {
    height: 75%;
  }
  @media only screen and (max-width: 987px) {
    width: 95% !important;
    padding-left: 0.5rem !important;
  }
  @media only screen and (max-width: 587px) {
    width: 95% !important;
    height: 88%;
    padding-left: 0.5rem !important;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const HeaderLeft = styled(Box)`
  width: 47%;
  margin-right: 1rem;
  .MuiTypography-root {
    font-family: "Montserrat", sans-serif;
    color: #fff;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-weight: 700;
    font-size: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: justify;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-weight: 600;
    font-size: 0.925rem;
    line-height: 34px;
    text-align: justify;
  }
  @media only screen and (max-width: 1220px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2.7rem;
    }
  }
  @media only screen and (max-width: 1101px) {
    width: 52%;
    /* .MuiTypography-root.MuiTypography-h4{
       font-size: 2.5rem;
     } */
  }
  @media only screen and (max-width: 1101px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2.4rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.825rem;
      line-height: 28px;
    }
  }
  @media only screen and (max-width: 890px) {
    width: 48%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2.1rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.725rem;
      line-height: 24px;
    }
  }
  @media only screen and (max-width: 783px) {
    width: 52%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.725rem;
      line-height: 24px;
    }
  }
  @media only screen and (max-width: 684px) {
    width: 52%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.65rem;
      line-height: 24px;
    }
  }
  @media only screen and (max-width: 617px) {
    width: 52%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.65rem;
      font-size: bolder;
      line-height: 39px;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.65rem;
      line-height: 24px;
    }
  }
  @media only screen and (max-width: 587px){
    width: 90%;
  }
  @media only screen and (max-width: 372px) {
    width: 95%;
  }
  @media only screen and (max-width: 330px) {
    
    .MuiTypography-root.MuiTypography-h4{
      font-size: 1.5rem;
    }
  }
  @media only screen and (max-width: 301px) {
    
    .MuiTypography-root.MuiTypography-h4{
      font-size: 1.3rem;
    }
  }
`;

export const ButtonBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: start;
  margin-top: 1.5rem;
  a {
    text-decoration: none;

    .MuiButton-root.MuiButton-contained {
      border-radius: 100px;
      background: #00d285;
      font-weight: 500;
      font-size: 0.8rem;
      /* line-height: 17px; */
      text-transform: none;
      font-family: "Montserrat", sans-serif;
      padding: 0.5rem 3rem;
    }
    .call {
      background: #ebfaf4 !important;
      color: #000;
      margin-left: 1.2rem;
    }
  }
  @media only screen and (max-width: 713px) {
    .MuiButton-root.MuiButton-contained {
      font-size: 0.7rem;
      padding: 0.5rem 1.8rem;

      &:last-of-type {
        margin-left: 0.5rem;
      }
    }
  }
  @media only screen and (max-width: 337px) {
    flex-direction: column;
    .MuiButton-root.MuiButton-contained {
      &:last-of-type {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }
`;
export const HeaderRight = styled(Box)`
  width: 45%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  .header-image {
    object-fit: contain;
    height: 95%;
    /* width: 100%; */
    position: relative;
    z-index: 1;
  }
  .header-shape {
    position: absolute;
    height: 70%;
    z-index: 0;
    bottom: -11%;
    right: 22%;
  }
  @media only screen and (min-width: 1320px) and (max-width: 2400px) {
    width: 40%;
    .header-shape {
      height: 80%;
      right: 25%;
      bottom: -17%;
    }
  }
  @media only screen and (max-width: 1240px) {
    .header-shape {
      right: 29%;
    }
  }
  @media only screen and (max-width: 899px) {
    .header-shape {
      right: 32%;
    }
  }
  @media only screen and (max-width: 799px) {
    width: 38%;
    .header-image {
      width: 100%;
    }
    .header-shape {
      right: 20%;
    }
  }
  @media only screen and (max-width: 729px) {
    width: 38%;
    .header-image {
      width: 100%;
    }
    .header-shape {
      right: 12%;
    }
  }
  @media only screen and (max-width: 684px) {
    .header-shape {
      right: 6%;
    }
  }
  @media only screen and (max-width: 644px) {
    .header-shape {
      right: -2%;
    }
  }
  @media only screen and (max-width: 620px) {
    .header-shape {
      height: 60%;
      bottom: -1%;
      right: 9%;
    }
  }
  @media only screen and (max-width: 587px) {
    width: 60%;
    .header-image {
      margin-top: 1rem;
      height: 50%;
    }
    .header-shape {
      height: 50%;
      bottom: 30%;
      right: 15%;
    }
  }
  @media only screen and (max-width: 537px) {
    .header-shape {
      right: 11%;
    }
  }
  @media only screen and (max-width: 487px) {
    width: 70%;
    .header-image {
      margin-top: 1rem;
      height: 60%;
    }
    .header-shape {
      height: 49%;
      right: 19%;
      bottom: 32%;
    }
  }
  @media only screen and (max-width: 417px) {
    .header-shape {
      height: 34%;
      right: 18%;
      bottom: 36%;
    }
  }
  @media only screen and (max-width: 380px) {
    .header-shape {
      height: 41%;
      right: 17%;
      bottom: 37%;
    }
  }
  @media only screen and (max-width: 330px) {
    .header-shape {
      height: 33%;
      right: 19%;
      bottom: 35%;
    }
  }
  @media only screen and (max-width: 290px) {
    .header-image {
      margin-top: -2rem;
    }
    .header-shape {
      height: 31%;
      right: 20%;
      bottom: 47%;
    }
  }
`;