import React from "react";
import {
  ContentChild,
  MainContainer,
  MainContent,
  TextBox,
  Title,
} from "./main.style";
import blogA from "../../../assets/images/blogA.png";
import blogB from "../../../assets/images/blogB.png";
import { Button, Typography } from "@mui/material";
import Nav from "../nav/Nav";
import { Link } from "react-router-dom";
const Main = () => {
  return (
    <MainContainer>
        <Nav />
      <MainContent>
        <ContentChild>
          <img src={blogA} alt="blogA" />
          <Typography variant="h6">
            How To Design The Perfect Hero Image: Guide, Practical Tips and
            Examples
          </Typography>
          <Typography variant="body1">
            Design it an important and visual illustration that revolve in our
            daly lives, we dont run from it we utilise and make use of it.
          </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
        </ContentChild>
        <ContentChild>
          <img src={blogB} alt="blogB" />
          <Typography variant="h6">
            How To Design The Perfect Hero Image: Guide, Practical Tips and
            Examples
          </Typography>
          <Typography variant="body1">
            Design it an important and visual illustration that revolve in our
            daly lives, we dont run from it we utilise and make use of it.
          </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
        </ContentChild>
        <ContentChild>
            <Title>
                <Typography variant="body1">Mobile Development</Typography>
            </Title>
          <TextBox>
            <Typography variant="h6">
              How To Design The Perfect Hero Image: Guide, Practical Tips and
              Examples
            </Typography>
            <Typography variant="body1">
              Design it an important and visual illustration that revolve in our
              daly lives, we dont run from it we utilise and make use of it.
            </Typography>
             <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
          </TextBox>
          <TextBox>
            <Typography variant="h6">
              How To Design The Perfect Hero Image: Guide, Practical Tips and
              Examples
            </Typography>
            <Typography variant="body1">
              Design it an important and visual illustration that revolve in our
              daly lives, we dont run from it we utilise and make use of it.
            </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
          </TextBox>

        </ContentChild>
      </MainContent>
    </MainContainer>
  );
};

export default Main;
