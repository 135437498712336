import { Button, Typography } from "@mui/material";
import React from "react";
import {
  BodyChild,
  BodyContainer,
  BodyLeft,
  BodyRight,
  Extra,
  Tech,
  Topbox,
  TopboxChild,
} from "./body.style";
import arrow from "../../../assets/images/arrow.png";
import expertiseA from "../../../assets/images//expertiseA.png";
import expertiseB from "../../../assets/images//expertiseB.png";
import expertiseC from "../../../assets/images//expertiseC.png";

import { Link } from "react-router-dom";
const Body = () => {
  return (
    <BodyContainer>
      <BodyChild>
        <BodyLeft>
          <Typography variant="body2"></Typography>
          <Typography variant="h4">OUR SERVICES</Typography>
          <Typography variant="body1">
            Methodologies & Technology We Use.
          </Typography>
          <Topbox>
            <TopboxChild>
              <Tech>
                <Typography variant="body1">01</Typography>
                <Typography variant="h6">
                  CSS<br></br>HTML5
                </Typography>
                <img src={arrow} alt="arrow" className="arrow" />
              </Tech>
              <Tech>
                <Typography variant="body1">02</Typography>
                <Typography variant="h6">
                  PHP<br></br>Laravel
                </Typography>
                <img src={arrow} alt="arrow" className="arrow" />
              </Tech>
              <Tech>
                <Typography variant="body1">03</Typography>
                <Typography variant="h6">
                  jQuery<br></br>Javascript
                </Typography>
                <img src={arrow} alt="arrow" className="arrow" />
              </Tech>
              <Tech>
                <Typography variant="body1">04</Typography>
                <Typography variant="h6">
                  React.js<br></br>React Native
                </Typography>
                <img src={arrow} alt="arrow" className="arrow" />
              </Tech>
            </TopboxChild>
          </Topbox>
          {/* <Button variant="contained">Read More</Button> */}
        </BodyLeft>
      </BodyChild>
      <BodyChild>
        <BodyLeft>
          <Typography variant="body2">01</Typography>
          <Typography variant="h4">Digital Product Design.</Typography>
          <Typography variant="body1">
            The best software has both a strong back-end and a good front-end.
            We've got the tools and expertise to assist you in creating an
            intuitive and engaging user experience that your customers will love
            and your rivals will envy. Our UX/UI Design Services include:
          </Typography>
          <ul>
            <li>Design Workshops</li>
            <li>UX & UI Consulting</li>
            <li>Creative Direction</li>
            <li>Prototyping</li>
            <li>Usability Testing</li>
          </ul>
          {/* <Button variant="contained">Read More</Button> */}
        </BodyLeft>

        <BodyRight>
          <img src={expertiseA} alt="expertiseA" className="expertiseA" />
        </BodyRight>
      </BodyChild>
      <BodyChild>
        <BodyRight>
          <img src={expertiseB} alt="expertiseB" className="expertiseB" />
        </BodyRight>
        <BodyLeft>
          <Typography variant="body2">02</Typography>
          <Typography variant="h4">Web & Mobile Development</Typography>
          <Typography variant="body1" className="second">
            We build custom applications to help companies save smarter, grow
            faster, serve better; through automation, business process
            management, to create unique advantages for the business. We have
            been trusted by many businesses to build their entire technology
            from the ground up. With a highly-qualified, committed, and
            results-driven team, we can provide modern, scalable, and easily
            maintainable software solutions.
          </Typography>
          {/* <Button variant="contained">Read More</Button> */}
        </BodyLeft>
      </BodyChild>
      <BodyChild>
        <BodyLeft>
          <Typography variant="body2">03</Typography>
          <Typography variant="h4">Cloud, DevOps & Deployment</Typography>
          <Typography variant="body1">
            We assist clients in leveraging cloud technologies while avoiding
            any of the IT pain points. We create public cloud applications to
            allow agile, future-ready business models, and we use automation to
            speed up and reduce costs in your infrastructure.
          </Typography>
          <Typography variant="body1">
            Our DevOps consulting services will support you with each of the
            three main elements of your DevOps transition, from deployment to
            optimization. Our DevOps services will assist you in selecting
            best-in-class resources, equipping your team with the necessary
            skills, and fine-tuning your processes in order to break down silos,
            collaborate more effectively, and achieve a cultural shift that
            supports DevOps thinking
          </Typography>
          {/* <Button variant="contained">Read More</Button> */}
        </BodyLeft>
        <BodyRight>
          <img src={expertiseC} alt="expertiseC" className="expertiseC" />
        </BodyRight>
      </BodyChild>

      <Extra>
        <Typography variant="h6">Let's build great things together!</Typography>
        <Typography variant="body1">
          Fill out this form and one of our client success managers will contact
          you within 24 hours. We have notifications set to make sure your
          message is received.
        </Typography>
        <Link to="/contact">
          <Button variant="contained">Contact</Button>
        </Link>
      </Extra>
    </BodyContainer>
  );
};

export default Body;
