import styled from "styled-components"
import {Box} from "@mui/material"

export const SubFooterContainer = styled(Box)`
  width: 100%;
  height: 30vh;
  box-sizing: border-box;
  background-color: #ebfaf4;
  padding: 7rem;
  @media only screen and (max-width: 960px) {
    padding: 6rem 4rem;
  }
  @media only screen and (max-width: 674px){
    padding: 4rem 2rem;
  }
  @media only screen and (max-width: 480px){
    padding: 3rem 1rem;
  }
`;

export const SubFooterContainerChild = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    color: white;
    text-decoration: none;
    .MuiButton-root.MuiButton-contained {
      border-radius: 100px;
      background: #00d285;
      font-weight: 500;
      font-size: 1rem;
      /* line-height: 17px; */
      font-family: "Poppins", sans-serif;
      padding: 0.5rem 2.5rem;
    }
  }
  @media only screen and (max-width: 490px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const TextBox = styled(Box)`
  display: block;
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif !important;
    font-size: 2rem;
    color: #262626;
    font-weight: 500;
    line-height: 35px;
  }
  .MuiTypography-root.MuiTypography-body1 {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    font-size: 1.2rem;
    margin-top: 0.5rem;
  }
  @media only screen and (max-width: 674px){
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (max-width: 548px){
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.6rem;
    }
  }
  @media only screen and (max-width: 320px){
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 0.95rem;
    }
  }
`;