import styled from "styled-components"
import { Box } from "@mui/material"


export const HeaderContainer = styled(Box)`
  width: 100%;
  height: 95vh;
  background: #ebfaf4;
  box-sizing: border-box;
  padding: 6rem 5rem 4rem 6rem;
  position: relative;
  display: flex;
  overflow: hidden;

  .groups {
    position: absolute;
    width: 167px;
    height: 151.34px;
    bottom: 35.5%;
    right: 15%;
  }
  .portfolio {
    position: absolute;
    top: 0;
    left: 20%;
  }
  @media only screen and (max-width: 1360px) {
    .portfolio {
      left: 10%;
    }
  }
  @media only screen and (max-width: 1319px) {
    .portfolio {
      top: -2%;
    }
  }
  @media only screen and (max-width: 1070px) {
   .groups{
     bottom: 27%;
   }
  }

  @media only screen and (max-width: 935px) {
    padding: 6rem 3rem 4rem 4rem;
  }
  @media only screen and (max-width: 894px) {
    .portfolio {
      display: none;
    }
  }
  @media only screen and (max-width: 715px) {
    padding: 6rem 1.5rem 4rem 3rem;
    .groups {
      width: 140px;
      height: 130px;
    }
  }
  @media only screen and (max-width: 587px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
    padding: 3rem 1.5rem;
    .groups {
      display: none;
    }
  }

  @media only screen and (max-width: 440px) {
    height: 70vh;
    padding: 3rem 0.5rem;
  }
  @media only screen and (max-width: 380px) {
    padding: 3rem 0.5rem;
  }
`;

export const HeaderLeft = styled(Box)`
  width: 45%;
  height: 95%;
  box-sizing: border-box;
  padding-left: 1rem;
  position: relative;
  .group {
    position: absolute;
    width: 107px;
    height: 151.34px;
    bottom: -3%;
    left: -3%;
    z-index: 0;
  }
  .about {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }
  @media only screen and (max-width: 1194px) {
    width: 40%;
  }
  @media only screen and (max-width: 1094px) {
    width: 45%;
  }
  @media only screen and (max-width: 994px) {
    padding-left: 0;
    .group {
      left: -8%;
    }
  }
  @media only screen and (max-width: 852px) {
    .about {
      width: 98%;
      height: 90%;
    }
    .group{
      bottom: 3%;
    }
  }
  @media only screen and (max-width: 715px) {
    .about {
      width: 98%;
      height: 80%;
    }
    .group{
      bottom: 15%;
    }
  }
  @media only screen and (max-width: 587px) {
    width: 80%;
    height: 68%;
    .about {
      height: 80%;
    }
    .group {
      bottom: 12%;
    }
  }
  @media only screen and (max-width: 444px) {
    .about {
      height: 75%;
    }
    .group {
      display: none;
    }
  }
  @media only screen and (max-width: 360px) {
    width: 95%;
  }
  @media only screen and (max-width: 315px) {
    .about {
    
      margin-top: 1rem;
    }
  }
`;

export const HeaderRight = styled(Box)`
  width: 50%;
  padding-left: 2rem;
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 45px;
    /* text-align: justify; */
    color: #000d2f;
  }
  @media only screen and (max-width: 1503px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }
  @media only screen and (max-width: 1261px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.6rem;
    }
  }

  @media only screen and (max-width: 1007px) {
    width: 55%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (max-width: 864px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 715px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 587px) {
    width: 90%;
    padding-left: 1rem;
  }
  @media only screen and (max-width: 380px) {
    width: 95%;
    padding-left: 0.5rem;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 0.9rem;
    }
  }
  @media only screen and (max-width: 301px) {
    margin-top: 2rem;
  }
`;
export const Title = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .line {
    width: 53px;
    height: 3px;
    background-color: #00d285;
    margin-right: 10px;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 3rem;
    line-height: 40px;
    text-align: justify;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1070px){
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2.2rem;
    }
  } 
  @media only screen and (max-width: 774px){
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  } 
  @media only screen and (max-width: 685px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }

  @media only screen and (max-width: 301px) {
    margin-top: 2rem;
  }
`;