import styled from "styled-components";
import { Box } from "@mui/material";

export const BodyContainer = styled(Box)`
width: 100%;
background-color: black;
padding: 3rem 0;
`

export const Title = styled(Box)`
  width: 90%;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .MuiButton-root.MuiButton-contained {
    background-color: #00d285;
    text-transform: none;
    font-weight: 500;
    font-size: 0.85rem;
    font-family: "Poppins", sans-serif;
    padding: 0.5rem 1.4rem;
    border-radius: 12px;
  }
  &::after {
    content: "";
    width: 75%;
    height: 1px;
    background-color: #00d285;
    position: absolute;
    top: 40%;
    right: 13%;
  }
  @media only screen and (max-width: 1400px) {
    &::after {
      width: 65%;
      right: 18%;
    }
  }
  @media only screen and (max-width: 1020px) {
    &::after {
      width: 55%;
      right: 22%;
    }
  }
  @media only screen and (max-width: 820px) {
    &::after {
      width: 45%;
      right: 27%;
    }
  }
  @media only screen and (max-width: 659px) {
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 12px;
    }
    .MuiButton-root.MuiButton-contained {
      padding: 0.3rem 0.8rem;
    }
    &::after {
      right: 28%;
    }
  }
  @media only screen and (max-width: 558px) {
    &::after {
      width: 40%;
      right: 32%;
    }
  }
  @media only screen and (max-width: 490px) {
    &::after {
      width: 35%;
      right: 35%;
    }
  }
  @media only screen and (max-width: 442px) {
    &::after {
      width: 30%;
      right: 38%;
    }
  }
  @media only screen and (max-width: 406px) {
    &::after {
      width: 25%;
      right: 40%;
    }
  }
  @media only screen and (max-width: 388px) {
    &::after {
      display: none;
    }
  }
  @media only screen and (max-width: 388px){
    .MuiTypography-root.MuiTypography-body1{
      font-size: 10px;
    }
    .MuiButton-root.MuiButton-contained{
      padding: 0.2rem 0.7rem;
      font-size: 11px;
    }
  }
`;
export const BodyContent = styled(Box)`
  width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
  padding-right: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: 587px){
    padding-right: 0;
    justify-content: center;
    width: 95%;
  }
`;

export const ContentChild = styled(Box)`
  width: 30%;
  margin-top: 2rem;
  img {
    width: 100%;
  }
  a {
    text-decoration: none;
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 30px;
    color: #00d285;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #ffffff;
  }
  .MuiButton-root.MuiButton-contained {
    background-color: #00d285;
    text-transform: none;
    font-weight: 500;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    margin-top: 1rem;
    border-radius: 3px;
    padding: 0.5rem 1.5rem;
  }
  @media only screen and (max-width: 1400px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 899px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.85rem;
    }
  }
  @media only screen and (max-width: 769px) {
    width: 45%;
    margin-bottom: 1.5rem;
  }
  @media only screen and (max-width: 587px) {
    width: 90%;
  }
  @media only screen and (max-width: 380px) {
    width: 95%;
  }
`;