import React from "react";
import { Typography } from "@mui/material";

import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  SubBox,
} from "./header.style";
import ellipse from "../../../assets/images/ellipse.png";
import partnership from "../../../assets/images/partnership.jpg";
import contact from "../../../assets/images/contact.png";

const Header = () => {
  return (
    <HeaderContainer>
      <img src={contact} alt="contact" className="contact-img" />
      <HeaderLeft>
        <img src={partnership} alt="partnership" className="partnership" />
      </HeaderLeft>
      <HeaderRight>
        <SubBox>
          <img src={ellipse} alt="ellipse" className="ellipse" />
          <Typography variant="h4" className='header'>PARTNERSHIP FOR AGENCIES</Typography>
        </SubBox>
        <Typography variant="body1">
          A reliable partner that delivers solutions to your business challenges
          andstays by your side in the long term. Having the whole spectrum of
          web development expertise, we are here to take care of your projects
          while making it possible for you to focus on growing your own
          business.
        </Typography>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
