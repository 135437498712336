import React from 'react'
import { HeaderContainer, HeaderLeft, HeaderRight, Title } from './header.style'
import group from "../../../assets/images/group.png";
import about from "../../../assets/images/about.png";
import portfolio from "../../../assets/images/portfolio.png";
import { Typography } from '@mui/material';

const Header = () => {
  return (
    <HeaderContainer>
      <img src={portfolio} alt="portfolio" className="portfolio" />
      <img src={group} alt="group" className="groups" />
      <HeaderLeft>
        <img src={group} alt="group" className="group" />
        <img src={about} alt="about" className="about" />
      </HeaderLeft>
      <HeaderRight>
        <Title>
          <div className="line"></div>
          <Typography variant="h4" className="header">OUR PORTFOLIO</Typography>
        </Title>
        <Typography variant="h4" className="subheader">A glimpse of some of our projects  and people we’ve been fortunate to work with</Typography>
      </HeaderRight>
    </HeaderContainer>
  )
}

export default Header