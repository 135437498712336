import React from "react";
import { Typography } from "@mui/material";
import { MainContainer, MainLeft, MainRight, SubBox } from "./main.style";
import cable from "../../../assets/images/cable.png";
import ellipse from "../../../assets/images/ellipse.png";

const Main = () => {
  return (
    <MainContainer>
      <MainLeft>
        <img src={cable} alt="cable" />
      </MainLeft>
      <MainRight>
        <SubBox>
          <div className="line"></div>
          <img src={ellipse} alt="ellipse"  className="ellipse"/>
          <Typography variant="h4" className="header">NEED A PRODUCT QUOTE</Typography>
        </SubBox>
        <Typography variant="h4" className="subheader">Quote Request</Typography>
        <Typography variant="body1">Get your free qoute on request, need you to fill all marked 
field. Make a call for more details required</Typography>
      </MainRight>
    </MainContainer>
  );
};

export default Main;
