import styled from "styled-components";
import { Box } from "@mui/material";

export const HeaderContainer = styled(Box)`
  width: 100%;
  height: 90vh;
  background: #ebfaf4;
  box-sizing: border-box;
  padding: 3.5rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  .call {
      position: absolute;
      top: 0.6%;
      left: 16%;
      z-index: 0;
  }

  @media only screen and (max-width: 1380px){
    .call {
      top: -4%;
    }
  }
  @media only screen and (max-width: 1280px){
    .call {
      top: -6%;
      left: 10%;
    }
  }
  @media only screen and (max-width: 1140px) {
    padding: 3.5rem 2rem 3.5rem 3.5rem;
  }
  @media only screen and (max-width: 1064px){
    .call{
      top: -10%;
      left: 2%;
    }
  }
  @media only screen and (max-width: 899px){
    .call{
     display: none;
    }
  }
  @media only screen and (max-width: 840px) {
    height: 80vh;
    align-items: flex-start;
  }
  @media only screen and (max-width: 760px) {
    height: 70vh;
    padding: 3.5rem 1.5rem 3.5rem 1.5rem;
  }
  @media only screen and (max-width: 473px) {
    
    height: 40vh;
    padding: 0 1.5rem;
  }
  @media only screen and (max-width: 470px) {
    height: 45vh;
    padding: 3rem 1.5rem;
  }
  @media only screen and (max-width: 305px) {
    padding: 3rem 0.5rem;
  }
`;

export const HeaderLeft = styled(Box)`
  position: relative;
  z-index: 2;
  width: 45%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 1290px) {
    width: 45%;
  }
  @media only screen and (max-width: 1180px) {
    width: 50%;
  }
  @media only screen and (max-width: 1084px) {
    width: 50%;
    img {
      height: 90%;
    }
  }

  @media only screen and (max-width: 940px) {
    width: 45%;
    img {
      height: 80%;
    }
  }

  @media only screen and (max-width: 840px) {
    img {
      height: 70%;
    }
  }
  @media only screen and (max-width: 473px) {
    display: none;
  }
`;

export const HeaderRight = styled(Box)`
  box-sizing: border-box;
  width: 47%;
  /* height: 100%; */
  padding: 2.9rem 4rem 2.9rem 3rem;
  position: relative;
  z-index: 2;

  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 550;
    font-size: 1rem;
    line-height: 37px;
  }
@media only screen and (max-width: 990px){
  width: 50%;
}
  @media only screen and (max-width: 940px) {
    padding: 2.9rem 2rem;
  }
  @media only screen and (max-width: 840px) {
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 729px) {
    padding: 2.9rem 0 2.9rem 2rem;
  }
  @media only screen and (max-width: 687px) {
    padding: 0.9rem 0 2.9rem 1rem;
  }
  @media only screen and (max-width: 473px) {
    width: 100%;
  }
  @media only screen and (max-width: 412px) {
    padding: 0;
  }
`;
export const SubBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  
  .line {
    width: 53px;
    height: 3px;
    background-color: #00d285;
    margin-right: 10px;
  }
  @media only screen and (max-width: 1240px) {
    .ellipse {
      right: 25%;
    }
  }
  
  @media only screen and (max-width: 787px) {
    margin-bottom: 0.4rem;
  }
  @media only screen and (max-width: 473px) {
 width: 100%;
  }
  @media only screen and (max-width: 367px) {
    .ellipse {
      width: 95px;
      height: 95px;
      right: -17%;
    }
  }
  @media only screen and (max-width: 331px) {
  }
`;