import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper";
import { Typography } from "@mui/material";
import "swiper/css"
// import 'swiper/modules/navigation/navigation.scss'; // core Swiper
import 'swiper/scss/navigation';
import {
  Testimonials,
  TestimonialBoxA,
  TestimonialBoxB,
  TestimonialChild,
  TestimonialContainer,
  SubText,
  BoxAChild,
} from "./testimonial.style";
// import testimony from "../../../assets/images/testimony.png";
import testimonialShape from "../../../assets/images/testimonial-shape.png";
import clientA from "../../../assets/images/clientA.png";
import clientB from "../../../assets/images/clientB.png";
import clientC from "../../../assets/images/clientC.png";
import clientD from "../../../assets/images/clientD.jpg";
import clientE from "../../../assets/images/clientE.png";
const Testimonial = () => {
  return (
    <TestimonialContainer style={{display: 'none'}}>
      <TestimonialChild>
        <TestimonialBoxA>
         <BoxAChild>
            <div className="line"></div>
          <Typography variant="h6 header">TESTIMONIAL</Typography>
         </BoxAChild>
         <Typography variant="h5 subheader">Our clients span across various markets in Nigeria. See how some of them feel about us.</Typography>
        </TestimonialBoxA>
        <TestimonialBoxB>
          <Swiper
            modules={[Navigation, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            autoplay={true}
            speed={1000}
            direction="vertical"
            autoplaySpeed={3000}
        navigation
          >
             <img src={testimonialShape} alt="testimonial" className="test" />
              {" "}
              <SwiperSlide>
                 
                  <Testimonials className="testimonials">
                <img src={clientA} alt="testimony" className="testimony" />
                <Typography variant="body1">
                  Working with the PETRONG team has been a huge success! They're
                  good people who care deeply about our business!
                </Typography>
                {/* <Typography variant="h6">John Doe</Typography> */}
                <SubText>
                  <Typography variant="h6">Fastcredit Limited</Typography>
                </SubText>
               
              </Testimonials>
              </SwiperSlide>
              <SwiperSlide>
              <Testimonials className="testimonials">
                <img src={clientB} alt="testimony" className="testimony" />
                <Typography variant="body1">
                 We have been very pleased with PETRONG services, and how they pay attention to details from design to implementation.
                </Typography>
                {/* <Typography variant="h6">John Doe</Typography> */}
                <SubText>
                  <Typography variant="h6">Fullrange MFB</Typography>
                </SubText>
               
              </Testimonials>
              </SwiperSlide>
              <SwiperSlide>
              <Testimonials className="testimonials">
                <img src={clientC} alt="testimony" className="testimony" />
                <Typography variant="body1">
                  The PETRONG team has worked together with us in developing softwares and applications for our clients.
                </Typography>
                {/* <Typography variant="h6">John Doe</Typography> */}
                <SubText>
                  <Typography variant="h6">Chronicles Software Development Company</Typography>
                </SubText> 
              </Testimonials>
           </SwiperSlide>
              <SwiperSlide>
              <Testimonials className="testimonials">
                <img src={clientD} alt="testimony" className="testimony" />
                <Typography variant="body1">
                  PETRONG team is a great team, they are always ready to help and support us. They're very sefless and efficient.
                </Typography>
                {/* <Typography variant="h6">John Doe</Typography> */}
                <SubText>
                  <Typography variant="h6">Credit Capital Services Limited</Typography>
                </SubText> 
              </Testimonials>
           </SwiperSlide>
              <SwiperSlide>
              <Testimonials className="testimonials">
                <img src={clientE} alt="testimony" className="testimony" />
                <Typography variant="body1">
                  The PETRONG team added alot of innovations to our existing product, making it better overall.
                </Typography>
                {/* <Typography variant="h6">John Doe</Typography> */}
                <SubText>
                  <Typography variant="h6">Kephren Business Limited</Typography>
                </SubText> 
              </Testimonials>
           </SwiperSlide>
          </Swiper>
        </TestimonialBoxB>
      </TestimonialChild>
    </TestimonialContainer>
  );
};

export default Testimonial;
