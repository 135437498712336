import styled from "styled-components"
import { Box } from "@mui/material"

export const BodyContainer = styled(Box)`
width: 100%;
padding: 4rem 5rem;
@media only screen and (max-width: 800px) {
  padding: 4rem 1.2rem;
}
`

export const BodyContent = styled(Box)`
  width: 100%;
  margin: 0 auto;
  position: relative;
  .chat {
    width: 5%;
    position: absolute;
    right: -2%;
  }

  @media only screen and (max-width: 800px) {
    width: 90%;
    .chat {
      width: 7%;
    }
  }
  @media only screen and (max-width: 440px){
.chat{
  width: 10%;
}
  }
  @media only screen and (max-width: 340px){
.chat{
  width: 14%;
}
  }
`;

export const TextBox = styled(Box)`
  width: 85%;
  .MuiTypography-root {
    color: #000;
    font-family: "Poppins", sans-serif;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 25px;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-weight: 400;
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 800px) {
    width: 92%;
  }

  @media only screen and (max-width: 581px) {
    .MuiTypography-root.MuiTypography-h4{
      font-size: 1.8rem;
    }
    .MuiTypography-root.MuiTypography-body1{
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 475px) {
    .MuiTypography-root.MuiTypography-h4{
      font-size: 1.5rem;
    }
  }

  @media only screen and (max-width: 396px) {
    .MuiTypography-root.MuiTypography-h4{
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 334px) {
    .MuiTypography-root.MuiTypography-h4{
      font-size: 1rem;
    }
    .MuiTypography-root.MuiTypography-body1{
      font-size: 0.7rem;
    }
  }
`;

export const LinkBox = styled(Box)`
  width: 85%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e6e6e6;
  align-items: center;
  img {
    width: 150px;
    height: auto;
    object-fit: contain;
    margin: 2.5rem 4rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  }
  @media only screen and (max-width: 980px) {
    img {
      margin: 2.5rem 3rem;
    }
  }
  @media only screen and (max-width: 932px) {
    img {
      margin: 2.5rem 2rem;
    }
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    img {
      margin: 2.5rem 2rem;
    }
  }
  @media only screen and (max-width: 485px) {
    img {
      margin: 2.5rem 1.2rem;
    }
  }
  @media only screen and (max-width: 428px) {
    img {
      width: 100px;
    }
  }
  @media only screen and (max-width: 361px) {
    img {
      margin: 2.5rem 0.9rem;
    }
  }
  @media only screen and (max-width: 340px) {
    justify-content: center;
    img {
      width: 150px;
    }
  }
`;

export const NolinkBox = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e6e6e6;
  img {
    width: 150px;
    height: auto;
    /* object-fit: contain; */
    margin: 2.5rem 4rem;
  }
  @media only screen and (max-width: 980px) {
    img {
      margin: 2.5rem;
    }
  }
  @media only screen and (max-width: 932px) {
    img {
      margin: 2.5rem 2rem;
    }
  }
  @media only screen and (max-width: 485px) {
    img {
      margin: 2.5rem 1.2rem;
    }
  }
  @media only screen and (max-width: 428px) {
    img {
      width: 120px;
    }
  }
  @media only screen and (max-width: 361px) {
    img {
      margin: 2.5rem 0.9rem;
    }
  }
  @media only screen and (max-width: 340px) {
    justify-content: center;
    img {
      width: 150px;
    }
  }
`;