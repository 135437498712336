import styled from "styled-components"
import { Box } from "@mui/material"

export const PromiseContainer = styled(Box)`
  width: 100%;
  /* height: 110vh; */
  background-color: #fff;
  box-sizing: border-box;
  padding: 4rem 3rem;
  /* @media only screen and (max-width: 1180px) {
    height: 130vh;
  } */
  @media only screen and (max-width: 860px) {
    height: unset;
  }
  @media only screen and (max-width: 740px) {
    padding: 3rem 1.6rem;
    /* height: 140vh; */
  }
  @media only screen and (max-width: 533px) {
    padding: 1.5rem 1.5rem;
    /* height: 180vh; */
  }
  @media only screen and (max-width: 480px) {
    padding: 1.5rem 1.5rem;
    height: unset;
  }
  @media only screen and (max-width: 380px) {
    padding: 1.5rem 1.5rem;
  }
  @media only screen and (max-width: 320px) {
    padding: 1.5rem 1.5rem;
  }
`;
export const Title = styled(Box)`
  text-align: center;
  margin-bottom: 3rem;

  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 54px;
    text-align: center;
    color: #000d2f;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 21px;
    text-align: center;
    font-family: "Monteserrat", sans-serif;
    color: #000d2f;
  }
`;

export const PromiseChild = styled(Box)`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: 533px) {
    flex-direction: column;
    justify-content: center;
  }
  @media only screen and (max-width: 480px){
    margin-bottom: 2rem;
  }
`;

export const PromiseItem = styled(Box)`
  width: 32%;
  box-sizing: border-box;
  padding: 2.5rem 1.5rem;
  margin-bottom: 1rem;
  background: rgba(235, 250, 244, 0.3);
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
 
  @media only screen and (max-width: 1040px) {
    padding: 1.5rem 1rem;
  }
  @media only screen and (max-width: 940px){
    width: 49%;
  }
  @media only screen and (max-width: 768px){
    width: 48%;
  }
  @media only screen and (max-width: 533px){
    width: 100%;
  }
`;

export const Text = styled(Box)`
  margin-left: 1rem;

  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #000d2f;
    font-family: "Poppins", sans-serif;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Monteserrat", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 29px;
    text-align: justify;
    color: #000d2f;
  }
  @media only screen and (max-width: 662px) {
    margin-left: 0.5rem;
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 20px;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 572px) {
    margin-left: 0.5rem;
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 18px;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 11px;
    }
  }
`;