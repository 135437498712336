import styled from "styled-components"
import {Box} from "@mui/material"

export const TeamContainer = styled(Box)`
  width: 100%;
  background-color: black;
  box-sizing: border-box;
  padding: 3rem;
  @media only screen and (max-width: 745px) {
    padding: 2rem;
  }
  @media only screen and (max-width: 439px) {
    padding: 2rem 1rem;
  }
  @media only screen and (max-width: 380px){
    padding: 2rem 0.4rem;
  }
`;

export const TeamChild = styled(Box)`
  width: 80%;
  margin: 0 auto;

  @media only screen and (max-width: 1225px) {
    width: 90%;
  }
  @media only screen and (max-width: 1074px) {
    width: 100%;
  }
  
`;

export const Title = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: white;
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 514px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1rem;
    }
  }
`;
export const TextContainer = styled(Box)`
  width: 55%;

  .MuiTypography-root.MuiTypography-h4 {
    color: #fff;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-h6 {
    color: #fff;
  }

  @media only screen and (max-width: 1268px) {
    width: 45%;
  }
  @media only screen and (max-width: 909px) {
    width: 50%;
  }
  @media only screen and (max-width: 827px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 723px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.6rem;
    }
  }
  @media only screen and (max-width: 621px) {
    width: 65%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.4rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.75rem;
    }
  }

  @media only screen and (max-width: 439px) {
    width: 80%;
  }
  @media only screen and (max-width: 380px) {
    width: 90%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 330px){
width: 98%;
.MuiTypography-root.MuiTypography-h4{
  font-size: 1rem;
}
.MuiTypography-root.MuiTypography-body1{
  font-size: 0.7rem;
}
  }
`;

export const Teams = styled(Box)`
width: 100%;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
margin-top: 3rem;
`

export const TeamItem = styled(Box)`
  text-align: center;
  background: #000000;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.offset{
    border: 5px solid inset #00d285;
    border-radius: 12.5px;
    padding: 0rem;
    width: 325px;
    height: 375px;
    background-color: #00d285;
  }
  img {
    width: 325px;
    height: 400px;
    border-radius: 12.5px;
    object-fit: cover;
    border: 5px solid #00d28500;
    margin-bottom: 0px;
    margin-right: -5px;
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    margin-top: 1rem;
    color: #fff;
    background-color: #00d285;
    padding: 0.5rem 1.5rem;
    border-radius: 12.5px;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Monteserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    text-align: center;
    margin-top: 1rem;
    color: #fff;
  }
  @media only screen and (max-width: 985px) {
    padding: 1rem;
  }
  @media only screen and (max-width: 899px) {
    padding: 1rem 0.7rem;
  }
  @media only screen and (max-width: 852px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 745px) {
    width: 100%;
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 460px) {
    width: 100%;
    div.offset{
      width: 325px;
      height: 375px;
    }
    img {
      width: 325px;
      height: 375px;
    }
    .MuiTypography-root.MuiTypography-h6{
      font-size: 14px;
    }
      .MuiTypography-root.MuiTypography-body1 {
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 380px) {
    padding: 1rem 0;
    .MuiTypography-root.MuiTypography-h6{
      font-size: 10px;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 8px;
    }
  }
`;