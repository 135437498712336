import { Typography } from "@mui/material";
import React from "react";
import {
  TeamChild, TeamContainer,
  TeamItem, Teams,
  TextContainer,
} from "./team.style";
import teamC from "../../../assets/images/teamC.jpg";
// import teamD from "../../../assets/images/teamD.jpg";
import team0 from "../../../assets/images/team0.jpg";
// import teamE from "../../../assets/images/teamE.jpeg";
const Team = () => {
  const teams = [
    {
      name: "Peter Eshowoeblim D.",
      title: "Founder & CEO",
      image: team0,
    },
    {
      name: "Oluwadamilola Samuel",
      title: "CTO & SENIOR FULLSTACK DEVELOPER",
      image: teamC,
    },
    // {
    //   name: "Emmanuel Adesina A.",
    //   title: "FULLSTACK DEVELOPER",
    //   image: teamE,
    // },
    // {
    //   name: "Oluwaseun Agboninu",
    //   title: "BACKEND DEVELOPER",
    //   image: teamD,
    // }
  ]
  return (
    <TeamContainer>
      <TeamChild>
        {/* <Title>
          <div className="line"></div>
          <Typography variant="h4">OUR TEAM</Typography>
        </Title> */}
        <TextContainer>
          <Typography variant="h4" className="header">
            Colaborating and optimizing for growth
          </Typography>
          <Typography variant="h6" className="subheader">
            At PETRONG we are powering the growth of Africa technology
            ecosystem
          </Typography>
        </TextContainer>
        <Teams>
          {
            teams.map((team, index) => {
              return (
                <TeamItem key={index}>
                  <div className="offset">
                    <img src={team.image} alt="team" />
                  </div>
                  <Typography variant="h6">{team.name}</Typography>
                  <Typography variant="body1">{team.title}</Typography>
                </TeamItem>
              )
            })
          }
        </Teams>
      </TeamChild>
    </TeamContainer>
  );
};

export default Team;
