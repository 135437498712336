import React from "react";
import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  SubBox,
} from "./header.style";

import call from "../../../assets/images/call.png";
import phone from "../../../assets/images/phone.png";
import { Typography } from "@mui/material";
const Header = () => {
  return (
    <HeaderContainer>
      <img src={call} alt="call" className="call" />
      <HeaderLeft>
        <img src={phone} alt="phone" className="phone" />
      </HeaderLeft>
      <HeaderRight>
        <SubBox>
          <div className="line"></div>
          <Typography variant="h4" className="header">Book a call</Typography>
        </SubBox>

        <Typography variant="body1">
          Select a suitable time slot and have a face-to-face video call with a
          project manager to discuss your needs.
        </Typography>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
