import styled from "styled-components";
import {Box} from "@mui/material"

export const HeaderContainer = styled(Box)`
  width: 100%;
  height: 120vh;
  box-sizing: border-box;
  padding: 6rem 5rem 2rem;
  background: #ebfaf4;
  position: relative;
  display: flex;

  .groups {
    position: absolute;
    width: 167px;
    height: 151.34px;
    bottom: -10.5%;
    right: 15%;
  }
  @media only screen and (max-width: 916px) {
    padding: 6rem 3rem 2rem;
  }

  @media only screen and (max-width: 610px) {
    flex-direction: column-reverse;
    height: 150vh;
    padding: 4rem 2rem 2rem;

    .groups {
      width: 147px;
      height: 141.34px;
      bottom: -9%;
      right: 10%;
    }
  }
  @media only screen and (max-width: 542px) {

    .groups {
      width: 147px;
      height: 141.34px;
      bottom: -9%;
      right: 6%;
    }
  }

  @media only screen and (max-width: 444px) {
    padding: 3rem 1rem 2rem;
    .groups {
      width: 127px;
      height: 121.34px;
      bottom: -9%;
      right: 10%;
    }
  }
  @media only screen and (max-width: 346px) {
    height: 160vh;
    padding: 2rem 0.5rem 2rem;
    .groups {
      width: 100px;
      height: 90.34px;
      bottom: -5%;
    }
  }

  @media only screen and (max-width: 312px) {
    height: unset;
    padding-bottom: 5rem;
    .groups{
      display: none;
    }
  }
`;

export const HeaderLeft = styled(Box)`
  width: 35%;
  height: 70%;
  box-sizing: border-box;
  padding-left: 1rem;
  position: relative;
  .group {
    position: absolute;
    width: 107px;
    height: 151.34px;
    bottom: -3%;
    left: -3%;
    z-index: 0;
  }
  .about {
    width: 90%;
    height: 100%;
    position: relative;
    z-index: 2;
  }
  @media only screen and (max-width: 1194px) {
    width: 40%;
  }
  @media only screen and (max-width: 1094px) {
    width: 45%;
  }
  @media only screen and (max-width: 994px) {
    padding-left: 0;
    .group {
      left: -8%;
    }
  }
  @media only screen and (max-width: 852px) {
    .about {
      width: 98%;
    }
  }
  @media only screen and (max-width: 610px) {
    width: 80%;
    height: 68%;
    margin-top: 7rem;
    .about {
      height: 70%;
    }
    .group {
      bottom: 12%;
    }
  }
  @media only screen and (max-width: 444px){
    .about{
      height: 60%;
    }
    .group{
      display: none;
    }
  }
  @media only screen and (max-width: 360px){
    width: 95%;
  }
  @media only screen and (max-width: 315px){
    margin-top: 2rem;
    padding-bottom: 5rem;
    .about{
      height: 50%;
    }
  }
`;
export const TextContainer = styled(Box)`
  width: 100%;

  .MuiTypography-root.MuiTypography-h4 {
    margin-bottom: 1.5rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 25px;
    color: #000d2f;
  }

  @media only screen and (max-width: 976px) {
    width: 50%;
  }
  @media only screen and (max-width: 743px) {
    width: 60%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }
  @media only screen and (max-width: 645px) {
    width: 70%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.6rem;
    }
  }
  @media only screen and (max-width: 579px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (max-width: 514px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 448px) {
    width: 85%;
  }
  @media only screen and (max-width: 312px) {
    width: 95%;
  }
`;
export const BottomLeft = styled(Box)`
  width: 50%;
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 45px;
    color: #000d2f;
    margin: 1rem 0 1rem;
  }
  @media only screen and (max-width: 852px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }

  @media only screen and (max-width: 587px) {
    width: 90%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.5rem;
    }
  }
  @media only screen and (max-width: 468px) {
    width: 97%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.3rem;
    }
  }
  @media only screen and (max-width: 388px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 339px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 288px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 0.9rem;
    }
  }
`;

export const TextBox = styled(Box)`
  width: 90%;
  position: absolute;
  box-sizing: border-box;
  padding: 1rem 1rem;
  background: #ffffff;
  border-radius: 10px;
  bottom: -18%;
  right: -4%;
  z-index: 4;
  .MuiTypography-root {
    font-family: "Poppins", sans-serif;
  }
  .MuiTypography-root.MuiTypography-body2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    /* text-align: justify; */
    color: rgba(0, 0, 0, 0.8);
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    margin-top: 0.4rem;
  }
  @media only screen and (max-width: 852px) {
    width: 100%;
  }
  @media only screen and (max-width: 707px) {
    bottom: -26%;
  }
  @media only screen and (max-width: 662px) {
    width: 110%;
  }
  @media only screen and (max-width: 610px) {
    width: 90%;
    bottom: 11%;
  }
  @media only screen and (max-width: 444px) {
    bottom: 21%;
    right: -12%;
  }
  @media only screen and (max-width: 360px) {
    right: -3%;
  }
  @media only screen and (max-width: 315px){
    bottom: -15%;
    padding: 1rem 0.5rem;
  }
`;
export const HeaderRight = styled(Box)`
  width: 48%;
  height: 80%;
  box-sizing: border-box;
  padding-left: 3rem;
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Monteserrat", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 29px;
    text-align: justify;
  }

  @media only screen and (max-width: 1194px) {
    width: 55%;
  }
  @media only screen and (max-width: 1062px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.5rem;
    }

    .MuiTypography-root.MuiTypography-body1 {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 852px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 610px) {
    width: 90%;
    height: 32%;
  }
  @media only screen and (max-width: 514px) {
    padding-left: 2rem;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.1rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 444px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.7rem;
    }
  }
  @media only screen and (max-width: 346px){
width: 100%;
.MuiTypography-root.MuiTypography-h4{
  font-size: 0.9rem;
}
  }
`;

export const Title = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .line {
    width: 53px;
    height: 3px;
    background-color: #00d285;
    margin-right: 10px;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 685px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }
`;