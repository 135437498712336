import styled from "styled-components";
import { Box } from "@mui/system";
import bg from "../../../assets/images/footer-bg.png";

export const FooterContainer = styled(Box)`
  width: 100%;
  /* height: 65vh; */
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  .overlay {
    position: absolute;
    top: 10%;
    left: 5%;
    width: 85%;
    height: 85%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

  /* @media only screen and (min-width: 1200px) {
    height: 77vh;
  } */
  @media only screen and (min-width: 1800px) {
    height: unset;
  }
  /* @media only screen and (max-width: 842px) {
    height: 95vh;
  }
  @media only screen and (max-width: 599px) {
    height: 107vh;
  } */
  @media only screen and (max-width: 480px) {
    height: unset;
  }
  /* @media only screen and (max-width: 380px) {
    height: 105vh;
  }
  @media only screen and (max-width: 300px) {
    height: 118vh;
  } */
`;

export const FooterChild = styled(Box)`
  width: 80%;
  margin: 4rem auto 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  @media only screen and (min-width: 789px) and (max-width: 1084px) {
    width: 90%;
  }
  @media only screen and (max-width: 786px) {
    width: 85%;
  }
  @media only screen and (max-width: 480px) {
    width: 95%;
    box-sizing: border-box;
    padding-right: 1rem;
  }
  @media only screen and (max-width: 420px) {
    .first, .third{
      width: 45% !important;
    }
    .second, .fourth{
      width: 55% !important;
    }
  }
`;
export const FooterBox = styled(Box)`
  width: 22%;
  &:last-of-type {
    width: 30%;
    @media only screen and (max-width: 842px) {
      width: 40%;
    }
    @media only screen and (max-width: 480px) {
      width: 49%;
    }
    @media only screen and (max-width: 345px) {
      width: 60%;
    }
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 1.5rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 0.6rem;
    color: #c4c4c4;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        border-bottom: 1px solid #00d285;
      }
    }
  }
  .logo {
    width: 80%;
    margin-top: 2rem;
  }

  @media only screen and (max-width: 842px) {
    width: 32%;
    margin-bottom: 1.5rem;
  }
  @media only screen and (max-width: 649px) {
    width: 40%;
  }
  @media only screen and (max-width: 599px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 480px) {
    width: 50%;
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1.1rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 10px;
    }
  }
  @media only screen and (max-width: 420px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
    }
    margin-bottom: 1rem;
  }
  @media only screen and (max-width: 380px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.9rem;
    }
    &:nth-of-type(3) {
      width: 80% !important;
    }
    &:last-of-type {
      width: 80% !important;
    }
  }
`;
export const FooterBoxChild = styled(Box)`
  width: 100%;
  display: flex;
`;
export const BoxChild = styled(Box)`
  display: block;
  &:last-of-type {
    margin-left: 1rem;
  }
`;

export const SocialBox = styled(Box)`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  .social-icon {
    width: 25px;
    height: 18px;
    object-fit: contain;
    background: #000d2f;
    color: #fff;
    padding: 10px;
    border-radius: 50%;
  }
  @media only screen and (max-width: 420px){
    width: 100%;
    .social-icon{
      width: 20px;
    }
  }
`;

export const ContactChild = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  img {
    height: 20px;
    width: 20px;
    margin-right: 4px;
  }
  &:not(last-of-type) {
    margin-top: 4px;
  }
  .MuiTypography-root.MuiTypography-body1 {
    display: flex;
    margin-bottom: 0;
    flex-wrap: wrap;
    a {
      margin-left: 2px;
      color: #00d285;
      text-decoration: none;
      word-wrap: break-word;
    }
  }
  @media only screen and (max-width: 420px){
    img{
      height: 15px;
      width: 15px;
    }
    .MuiTypography-root.MuiTypography-body1{
      margin-left: 0.2rem;
    }
  }
`;

export const BackBox = styled(Box)`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  margin: 3rem auto 0;
  position: relative;
  z-index: 2;
  cursor: pointer;
  img {
    width: 7%;
  }
  @media only screen and (min-width: 789px) and (max-width: 1084px) {
    width: 90%;
  }
  @media only screen and (max-width: 786px) {
    width: 85%;
  }
  @media only screen and (max-width: 480px) {
    width: 95%;
    margin-top: 5px;
    img {
      width: 11%;
    }
  }
`;

export const CopyrightBox = styled(Box)`
  width: 80%;
  margin: 0 auto 3rem;
  position: relative;
  z-index: 2;

  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #ffffff;
  }
  @media only screen and (min-width: 789px) and (max-width: 1084px) {
    width: 90%;
  }
  @media only screen and (max-width: 786px) {
    width: 85%;
  }
  @media only screen and (max-width: 480px) {
    width: 95%;
    margin-top: 5px;
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 10px;
    }
  }
`;
