import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    body {
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 10px;
  }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #424242; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #212121; 
}
    .MuiModal-root.MuiDrawer-root {
        width: 100%;
        .MuiPaper-root.MuiDrawer-paper {
            width: 45%;
            align-items: center;
            background-color: #00D285;
            .MuiBox-root {
             
               .MuiList-root {
                   list-style-type: none;
                   .MuiListItem-root {
                    letter-spacing: -0.015em;
                    color: #fff;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 18px;
                    a {
                        width: 100%;
                        text-decoration: none;
                        color: white;
                        .MuiButtonBase-root.MuiButton-root {
                            font-size: 15px;
                            width: 95%;
                            border-radius: 6px;
                            font-family: "Poppins", sans-serif;
                            font-weight: 500;
                            &:hover {
                                border: 1px solid #ffffff;
                            }
                        }
                       
                        
                    }
                   }
               }
            }
        }
        
    }
    }
    .colorChange {
        background-color: #000 !important;
       @media only screen and (max-width: 899px){
            .MuiPaper-root.MuiAppBar-root{
            .menu-div {
      display: flex;
      justify-content: flex-end;
      .MuiButton-root.MuiButton-text {
        text-transform: none;
        color: #00d285 !important;
        padding: 0;
        min-width: 15px;
        .MuiSvgIcon-root {
          color: ${({ isBlack }) =>
    isBlack ? "#00d285" : "#00d285"} !important;
        }
      }
    }
        }
       }
    }
    
`;
