import { useEffect } from "react";
import { Helmet } from "react-helmet";
import chat from "../../assets/images/chat.png";
import Navbar from "../../components/shared-components/navbar/Navbar";
import Subfooter from "../../components/shared-components/subfooter/Subfooter";
import Footer from "../../components/shared-components/footer/Footer";
import { AboutContainer } from "./about.style";
import Header from "../../components/about-components/header/Header";
import Body from "../../components/about-components/body/Body";
import Team from "../../components/about-components/team/Team";

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <AboutContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Petrong Software Solutions - About" />
        <meta
          name="keywords"
          content="Petrong, Petrong Software, Petrong Software Solutions"
        />
        <meta name="author" content="Petrong Software" />
        <meta name="robots" content="index,follow" />
        <title>Petrong - About</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
        />
      </Helmet>
      <Navbar className="contact-navbar" isBlack="true" />
      <Header />
      <a href=" https://wa.me/2348066071971" target="_blank" rel="noreferrer">
        <img src={chat} alt="chat" className="chat" />
      </a>
      <Body />
      <Team />
      <Subfooter />
      <Footer />
    </AboutContainer>
  );
};

export default About;
