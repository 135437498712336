import styled from "styled-components";
import { Box } from "@mui/material";

export const FootContainer = styled(Box)`
width: 100%;
box-sizing: border-box;
padding: 3rem 5rem;
display: flex;
justify-content: flex-start;
align-items: center;
@media only screen and (max-width: 793px){
  padding: 3rem 2rem;
}
@media only screen and (max-width: 587px){
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 390px){
  padding: 3rem 1rem;
}
`

export const FootLeft = styled(Box)`
  width: 35%;
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 45px;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 1503px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }

  @media screen and (max-width: 1261px) {
    width: 45%;
    .MuiTypography-root.MuiTypography-h4{
      font-size: 1.5rem;
    }
    .MuiTypography-root.MuiTypography-body1{
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 895px){
    width: 55%;
    .MuiTypography-root.MuiTypography-h4{
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 698px) {
    width: 60%;
    .MuiTypography-root.MuiTypography-h4{
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 587px) {
    width: 90%;
  }
`;

export const FootRight = styled(Box)`
  display: flex;
  .MuiPaper-root {
    background: #ebfaf4;
    border-radius: 10px;
    box-sizing: border-box;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    margin-left: 3rem;

    .MuiTypography-root.MuiTypography-body1 {
      font-weight: 500;
      font-size: 1.8rem !important;
      line-height: 150% !important;
      color: #00d285;
    }
    .MuiTypography-root.MuiTypography-body2 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 1.8rem !important;
    }
  }

  @media only screen and (max-width: 1070px) {
    .MuiPaper-root {
      width: 200px;
    }
  }
  @media only screen and (max-width: 698px) {
    .MuiPaper-root {
      width: 170px;
      .MuiTypography-root.MuiTypography-body1,
      .MuiTypography-root.MuiTypography-body2 {
        font-size: 1.2rem !important;
      }
    }
  }
  @media only screen and (max-width: 587px) {
    width: 80%;
    margin-top: 2rem;
  }

  @media only screen and (max-width: 440px) {
    .MuiPaper-root {
      width: 170px !important;
      margin-left: 0.5rem;
    }
  }
  @media only screen and (max-width: 380px) {
  width: 95%;
  }
`;