import React from "react";
import {
  CoreBoxA,
  CoreBoxB,
  Corecontainer,
  CoreContainerChild,
  ServiceBox,
  TextBox,
} from "./core.style";
import coreImage from "../../../assets/images/core-image.jpg";
import coreImage2 from "../../../assets/images/guy-shows-document-girl-group-young-freelancers-office-have-conversation-working.jpg";
import { Typography } from "@mui/material";

import cloud from "../../../assets/images/Cloud.png";
import Mobiledev from "../../../assets/images/Mobiledev.png";
import Maintenance from "../../../assets/images/Maintenance.png";
import onboarding from "../../../assets/images/onboarding.png";
import uiux from "../../../assets/images/uiux.png";
// import Web from "../../../assets/images/web.png";
import Web from "../../../assets/images/programming-background-with-person-working-with-codes-computer.jpg";

const Core = () => {
  return (
    <Corecontainer>
      <CoreContainerChild>
        <CoreBoxA>
          <img src={coreImage} alt="core" className="core-image"  style={{objectFit: 'cover'}}/>
          <img src={coreImage2} alt="core" className="core-image" style={{marginTop: '20px',objectFit: 'cover'}} />
        </CoreBoxA>
        <CoreBoxB>
          <Typography variant="h4">Our Core Services</Typography>
          <Typography variant="body1">
            Our technology solutions are perfectly designed to ensure the
            virtual world meets your needs.
          </Typography>
          {/* <ServiceBox>
            <img src={onboarding} alt="Onboard" className="" />

            <TextBox>
              <Typography variant="h6">
                Customer Onboarding for Microfinance Institutions
              </Typography>
              <Typography variant="body1">
                We are helping microfinance institutions in Africa to optimize
                their processes, reduce error rates, improve turn around time
                and automate their customer validation process for speedy and
                more error-free customer onboarding
              </Typography>
            </TextBox>
          </ServiceBox> */}
          <ServiceBox>
            <img src={Web} alt="web" />
            <TextBox>
              {" "}
              <Typography variant="h6">Web Application Development</Typography>
              <Typography variant="body1">
                We ensure Security, Performance, Scalability, Reusable
                Components, SEO compliance and Responsiveness - the perfect
                ingredients for building websites, ecommerce sites, web apps or
                enterprise solutions; we also build powerful solutions for email
                marketing.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={Mobiledev} alt="mobile" className="mobile" />
            <TextBox>
              <Typography variant="h6">
                Mobile Application Development
              </Typography>
              <Typography variant="body1">
                We know the ins and outs of Mobile Application development and
                we can help you get your Mobile App project running in a matter
                of weeks. We do highly customizable, easy-to-manage, and
                extendable solutions.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={cloud} alt="vue" className="vue" />
            <TextBox>
              <Typography variant="h6">
                Cloud server setup & configuration
              </Typography>
              <Typography variant="body1">
                We assist you in leveraging cloud technologies while helping you
                avoiding any of the IT pain points. We create public cloud
                applications to allow agile, future-ready business models, and
                we use automation to speed up and reduce costs in your
                infrastructure.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={Maintenance} alt="vue" className="vue" />
            <TextBox>
              <Typography variant="h6">
                Maintenance and Enhancement of existing software systems
              </Typography>
              <Typography variant="body1">
                It does not matter if you already have a fully built solution,
                we can join you in your journey of taking your products from the
                current level to bigger successes through strategic innovative
                incremental enhancements, routine maintenance and real-time
                support.
              </Typography>
            </TextBox>
          </ServiceBox>
          <ServiceBox>
            <img src={uiux} alt="uiux" className="" />
            <TextBox>
              <Typography variant="h6">
                User Interface and User Experience Design
              </Typography>
              <Typography variant="body1">
                We can translate your ideas and concepts into detailed user
                interfaces and user experience designs to enable you alternate
                between the various prototyped features.
              </Typography>
            </TextBox>
          </ServiceBox>
        </CoreBoxB>
      </CoreContainerChild>
    </Corecontainer>
  );
};

export default Core;
