import styled from "styled-components";
import { Box } from "@mui/material";
import bg from "../../../assets/images/pacioli-pss.png";

export const AboutContainer = styled(Box)`
  width: 100%;
  background: #92DFFB;
  position: relative;
  box-sizing: border-box;
  padding: 2rem;
  color: white !important;

  @media only screen and (max-width: 998px) {
    height: 90vh;
  }
  @media only screen and (max-width: 587px) {
    height: 100vh;
    background-color: black;
    background-image: white;
  }
  @media only screen and (max-width: 480px){
    height: unset;
  }
   @media only screen and (max-width: 428px) {
    padding: 1.2rem 0.6rem;
  }
`;

export const AboutTitle = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-left: 6.2rem;

  .line {
    width: 44px;
    height: 2px;
    background-color: #00d285;
    margin-right: 10px;
  }
  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
  }
  @media only screen and (max-width: 587px){
    margin-bottom: 0;
    justify-content: center;
    padding-left: 0;
    .MuiTypography-root.MuiTypography-h6{
      color: white;
    }
  }
`;
export const AboutChild = styled(Box)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;

  @media only screen and (max-width: 587px) {
    justify-content: center;
    background-image: none;
    padding: 1rem;
  }
`;

export const AboutBoxA = styled(Box)`
  width: 55%;
  height: 100%;
  img {
    width: 90%;
    height: 100%;
    border-radius: 10px;
    margin-right: -0.5rem;
  }
  @media only screen and (max-width: 768px) {
    img {
      height: 90%;
    }
  }
  @media only screen and (max-width: 587px) {
    height: 55%;
    width: 80%;
  }
  @media only screen and (max-width: 480px) {
    width: 95%;
    margin-bottom: 2rem;
    img {
      height: auto;
      /* object-fit: contain; */
    }
  }
  @media only screen and (max-width: 320px) {
    width: 97%;
    height: 40%;
    img {
      height: 95%;
    }
  }
`;

export const AboutBoxB = styled(Box)`
  width: 45%;
  text-align: start;
  .MuiTypography-root {
    font-family: "Montserrat", sans-serif;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 44px;
    text-align: justify;
    // color: #262626;
    color: white !important;
    margin-bottom: 2rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 30px;
    text-align: justify;
    // color: black;
    color: white !important;
  }

  @media only screen and (max-width: 976px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }
  @media only screen and (max-width: 885px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.65rem;
    }
  }
  @media only screen and (max-width: 818px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.55rem;
    }
  }
  @media only screen and (max-width: 773px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.45rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.9rem;
    }
    .MuiButton-root.MuiButton-contained {
      font-size: 0.85rem;
      padding: 0.5rem 1.5rem;
    }
  }
  @media only screen and (max-width: 726px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.35rem;
      margin-bottom: 1rem;
    }
  }
  @media only screen and (max-width: 681px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.25rem;
    }
    .MuiButton-root.MuiButton-contained {
      font-size: 0.7rem;
      padding: 0.5rem 1.2rem;
    }
  }
  @media only screen and (max-width: 634px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.15rem;
    }
  }
  @media only screen and (max-width: 589px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.05rem;
    }
  }
  @media only screen and (max-width: 587px) {
    width: 80%;
    .MuiTypography-root.MuiTypography-h4 {
      color: white;
      margin-bottom: 0;
    }
    .MuiTypography-root.MuiTypography-body1 {
      color: white;
    }
    .MuiButton-root.MuiButton-contained {
      margin-top: 1rem;
    }
  }
  @media only screen and (max-width: 480px) {
    width: 95%;
  }
  @media only screen and (max-width: 320px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 0.95rem;
    }
  }
`;

export const ButtonBox = styled(Box)`
  width: 100%;
  display: flex;
  padding: 0 0.5rem;
  
  .MuiButton-root.MuiButton-contained {
    font-family: "Montserrat", sans-serif;
    text-transform: none;
    border-radius: 100px;
    background: white;
    margin-top: 2rem;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 0.4rem 2.5rem;
    color: black !important;

    a {
      margin: 0;
      color: unset;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    &:last-of-type {
      margin-left: 2rem;
    }
  }
  @media only screen and (max-width: 640px) {
    .MuiButton-root.MuiButton-contained {
      margin-top: 1.4rem;
    }
  }
  @media only screen and (max-width: 354px) {
    .MuiButton-root.MuiButton-contained {
      margin-top: 1rem;
      &:last-of-type {
        margin-left: 0.5rem;
      }
    }
  }
  @media only screen and (max-width: 320px) {
    .MuiButton-root.MuiButton-contained {
      padding: 0.4rem 1rem;
    }
  }
`;
