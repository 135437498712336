import styled from "styled-components";
import { Box } from "@mui/material";

export const SubContainer = styled(Box)`
  width: 100%;
  height: 115vh;
  background-color: white;
  box-sizing: border-box;
  padding: 2rem 5rem;
  margin-bottom: 4rem;

  @media only screen and (max-width: 986px) {
    padding: 2.5rem 2rem;
  }
  @media only screen and (max-width: 794px) {
   height: 125vh;
  }
  @media only screen and (max-width: 596px) {
    height: 160vh;
    padding: 2.5rem 0.5rem;
  }
  @media only screen and (max-width: 423px) {
    height: 170vh;
  }
  @media only screen and (max-width: 340px){
      padding: 2.5rem 0;
  }
  @media only screen and (max-width: 302px) {
    height: 180vh;
  }
`;

export const SubChild = styled(Box)`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 986px) {
    width: 90%;
  }
  @media only screen and (max-width: 596px) {
    justify-content: center;
  }
`;

export const SubLeft = styled(Box)`
  width: 40%;
  height: 90%;

  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.48rem;
    line-height: 26px;
    color: #000d2f;
    margin-bottom: 1rem;
  }
  .MuiButton-root.MuiButton-contained {
    font-family: "Poppins", sans-serif;
    width: 100%;
    background: #00d285;
    margin-top: 1rem;
    border-radius: 5px;
    text-transform: none;
    font-weight: 600;
    font-size: 14px;
  }
  .MuiTypography-root.MuiTypography-body2 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 21px;
    color: #000d2f;
    margin-top: 1.5rem;
  }
  @media only screen and (max-width: 726px) {
    width: 45%;
  }
  @media only screen and (max-width: 652px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 596px) {
    width: 85%;
  }
  @media only screen and (max-width: 326px) {
    width: 90%;
  }
  @media only screen and (max-width: 306px) {
    width: 97%;
  }
`;

export const Title = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;

  .line {
    width: 44px;
    height: 2px;
    background-color: #00d285;
    margin-right: 10px;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    color: #000d2f;
  }
  @media only screen and (max-width: 587px) {
    margin-bottom: 0;
    padding-left: 0;
  }
`;

export const Inputbox = styled(Box)`
  width: 100%;
  margin-bottom: 1.5rem;
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 21px;
    color: #000d2f;
  }
  
  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    .MuiInputBase-root.MuiOutlinedInput-root {
      background: #ebfaf4;
      border: 1px solid #ebfaf4;
      outline: none;
      .MuiInputBase-input.MuiOutlinedInput-input {
        height: 15px;
        border: none;
        outline: none;
        border-radius: 5px;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #ebfaf4 !important;
      }
    }
  }
`;
export const SubRight = styled(Box)`
  width: 40%;
  padding-top: 3rem;
  position: relative;
  img {
    margin-top: 1rem;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0.1rem;
    width: 27px;
    height: 22.96px;
    object-fit: contain;
  }
  &::after {
    content: "";
    position: absolute;
    height: 150px;
    width: 1px;
    background-color: #dedcdc;
    top: 36%;
    left: 5%;
  }
  @media only screen and (max-width: 856px) {
    width: 50%;
  }
  @media only screen and (max-width: 596px) {
    width: 85%;
  }
  @media only screen and (max-width: 326px) {
    width: 92%;
  }
  @media only screen and (max-width: 306px) {
    width: 98%;
  }
`;

export const Top = styled(Box)`
  width: 75%;
  display: flex;
  align-items: flex-start;
  margin-top: 2rem;

  @media only screen and (max-width: 856px) {
    width: 85%;
  }
  @media only screen and (max-width: 626px) {
    width: 92%;
  }
`;
export const Bottom = styled(Box)`
  width: 70%;
  display: flex;
  margin-top: 4rem;
  align-items: flex-start;
  .line {
    padding: 0.4rem;
  }
  @media only screen and (max-width: 856px) {
    width: 80%;
  }
  @media only screen and (max-width: 626px) {
    width: 92%;
  }
`;
export const TextBox = styled(Box)`
  margin-left: 1.5rem;

  .MuiTypography-root.MuiTypography-h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 15px;
    color: #000d2f;
    margin-bottom: 0.5rem;
  }

  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 34px;
    color: #000000;
    text-align: justify;
  }
  @media only screen and (max-width: 306px){
      margin-left: 1rem;
  }
`;
