
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { GlobalStyles } from './app.style';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Quote from './pages/quote/Quote';
import About from './pages/about/About';
import Call from "./pages/call/Call";
import Expertise from './pages/expertise/Expertise';
import Partnership from './pages/partnership/Partnership';
import Product from './pages/product/Product';
import Portfolio from './pages/portfolio/Portfolio';
import Blog from './pages/blog/Blog';
import SingleBlog from './pages/single-blog/SingleBlog';

function App() {
  return (
    <div className="App">
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/call" element={<Call />} />
          <Route path="/expertise" element={<Expertise />} />
          <Route path="/partnership" element={<Partnership />} />
          <Route path="/product" element={<Product />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/post" element={<SingleBlog />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
