import React from "react";
import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  TextBox,
  Title,
  TextContainer,
  BottomLeft,
} from "./header.style";
import about from "../../../assets/images/about.png";
import group from "../../../assets/images/group.png";
import { Typography } from "@mui/material";
import { BodyBottom } from "../body/body.style";

const Header = () => {
  return (
    <HeaderContainer>
      <img src={group} alt="group" className="groups" />
      <HeaderLeft>
        <img src={group} alt="group" className="group" />
        <img src={about} alt="about" className="about" />
        <TextBox>
          <Typography variant="body2">
            We have served individuals and businesses from various markets such
            as Fintech, Edtech, Non-profits, Auto Services, etc. to a tune of
            over 20 businesses who have benefitted directly from our services
            and over 30,000 individuals who have benefited indirectly in
            Nigeria.
          </Typography>
        </TextBox>
      </HeaderLeft>
      <HeaderRight>
        <Title>
          <div className="line"></div>
          <Typography variant="h4" className="header">
            WHO WE ARE
          </Typography>
        </Title>
        <Typography variant="h4" className="subheader">
          We combine our experience and passion to develop solutions that
          empower businesses.
        </Typography>
        <Typography variant="body1">
          We are Petrong Software Solutions, a team of committed technology
          specialists that are known to provide Solutions that digitizes and
          automates financial processes for SMEs and Microfinance institutions
          using some of the latest software development technologies. We also do
          general software services that cover mobile app development, web
          application development and cloud server setup and configuration.
        </Typography>{" "}
        <br />
        <Title>
          <div className="line"></div>
          <Typography variant="h4" className="header">
            Mission and Vision Statement
          </Typography>
        </Title>
        <TextContainer>
          <Typography variant="h4" className="subheader">
            Mission
          </Typography>
          <Typography variant="body2">
            To develop and deploy highly functional and efficient software
            solutions which optimize processes for businesses which directly
            impact both low-level income earners and the high-level income
            earners in Africa{" "}
          </Typography>
          <br></br>
          <Typography variant="h4" className="subheader">
            Vision
          </Typography>
          <Typography variant="body2">
            To solve real life problems and improve the quality of life equally
            for both the low-income and high-income population in Africa using
            software technology.{" "}
          </Typography>
        </TextContainer>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
