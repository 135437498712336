import { Paper, Typography } from '@mui/material'
import React from 'react'
import { FootContainer, FootLeft, FootRight } from './foot.style'

const Foot = () => {
  return (
    <FootContainer>
    <FootLeft>
        <Typography variant="h4">Creating What You Need,
The Way You Want Them</Typography>
        <Typography variant="body1">Petrong developers have excelled in a number of fields due to their 
deep expertise in software development as a service.</Typography>
    </FootLeft>
    <FootRight>
        <Paper>
            <Typography variant="body1">Fast</Typography>
            <Typography variant="body2">Delivery</Typography>
        </Paper>
        <Paper>
            <Typography variant="body1">Advanced</Typography>
            <Typography variant="body2">Rate</Typography>
        </Paper>
    </FootRight>
    </FootContainer>
  )
}

export default Foot