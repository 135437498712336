import React from 'react'
import { HeaderContainer, HeaderContent } from './header.style'
import contact from "../../../assets/images/contact.png";
import group from "../../../assets/images/group.png";
import { Typography } from '@mui/material';

const Header = () => {
  return (
    <HeaderContainer>
        <img src={contact} alt="contact" className="contact-img" />
        <img src={group} alt="group" className="group" />
        <HeaderContent>
            <Typography variant="h4">Dedicated product for you</Typography>
            <Typography variant="body1">Our Products are crafted from surveys and observation of the problems encountered in general everyday life of individuals and businesses. They are ready-to-use solutions that can be adapted on the fly to specifically solve the identified problems they were targeted at. With our products, think improved productivity, income/revenue and better lifestyle of all the users</Typography>
        </HeaderContent>
    </HeaderContainer>
  )
}

export default Header