import styled from "styled-components";
import { Box } from "@mui/material";

export const AboutContainer = styled(Box)`
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
  .chat {
    position: fixed;
    right: 7%;
    bottom: 2%;
    z-index: 6;
    @media only screen and (max-width: 420px) {
      width: 15%;
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #424242;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #212121;
  }
`;