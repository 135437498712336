import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import chat from "../../assets/images/chat.png";
import { ProductContainer } from "./product.style";
import Navbar from "../../components/shared-components/navbar/Navbar";
import Subfooter from "../../components/shared-components/subfooter/Subfooter";
import Footer from "../../components/shared-components/footer/Footer";
import Body from "../../components/product-components/body/Body";
import Header from "../../components/product-components/header/Header";

const Product = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <ProductContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Petrong Software Solutions - Product"
        />
        <meta name="keywords" content="Petrong, Software, Company, Product" />
        <meta name="author" content="Petrong Software" />
        <meta name="robots" content="index,follow" />
        <title>Petrong - Product</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
        />
      </Helmet>
      <Navbar className="contact-navbar" isBlack="true" />
      <Header />
      <a href=" https://wa.me/2348066071971" target="_blank" rel="noreferrer">
        <img src={chat} alt="chat" className="chat" />
      </a>
      <Body />
      <Subfooter />
      <Footer />
    </ProductContainer>
  );
};

export default Product;
