import styled from "styled-components";
import { Box } from "@mui/material";

export const HeaderContainer = styled(Box)`
  width: 100%;
  height: 95vh;
  background: #ebfaf4;
  box-sizing: border-box;
  padding: 3.5rem;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  .contact-img {
    position: absolute;
    z-index: 0;
    top: 0%;
    left: 18.5%;
  }
  @media only screen and (max-width: 1240px) {
    .contact-img {
      top: -7%;
      left: 14%;
    }
  }
  @media only screen and (max-width: 1140px) {
    padding: 3.5rem 2rem 3.5rem 3.5rem;
    .contact-img {
      left: 9%;
    }
  }
  @media only screen and (max-width: 970px) {
    .contact-img {
      left: 4%;
    }
  }
  @media only screen and (max-width: 927px) {
    align-items: flex-start;
  }
  @media only screen and (max-width: 872px) {
    align-items: center;
  }
  @media only screen and (max-width: 840px) {
    height: 90vh;
    align-items: flex-start;
    padding: 3.5rem 1.5rem 3.5rem 1.5rem;
    .contact-img {
      top: -25%;
    }
  }
  @media only screen and (max-width: 760px) {
    
    .contact-img {
      display: none;
    }
  }
  @media only screen and (max-width: 687px) {
    justify-content: center;
    height: 70vh;
  }
  @media only screen and (max-width: 470px) {
    height: 65vh;
    padding: 0 0.5rem;
  }
`;

export const HeaderLeft = styled(Box)`
  position: relative;
  z-index: 2;
  width: 45%;
  height: 100%;
  img {
    width: 100%;
    height: 90%;
  }
  @media only screen and (max-width: 1290px) {
    width: 45%;
  }
  @media only screen and (max-width: 1180px) {
    width: 50%;
  }
  @media only screen and (max-width: 1084px) {
    width: 50%;
    img {
      height: 90%;
    }
  }

  @media only screen and (max-width: 940px) {
    
    img {
      height: 80%;
    }
  }

  @media only screen and (max-width: 840px) {
    img {
      height: 85%;
    }
  }
  @media only screen and (max-width: 780px) {
    img {
      height: 75%;
    }
  }
  @media only screen and (max-width: 687px) {
    display: none;
  }
`;

export const HeaderRight = styled(Box)`
  box-sizing: border-box;
  width: 45%;
  padding: 0 4rem 2.9rem 3rem;
  .MuiTypography-root.MuiTypography-body1 {
    text-align: justify;
  }

  @media only screen and (max-width: 1208px) {
    width: 50%;
    .MuiTypography-root.MuiTypography-body1{
      font-size: 1.6rem;
    }
  }
  @media only screen and (max-width: 1080px) {
    padding: 2.9rem 1.2rem;
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 1.5rem;
    }
  }

  @media only screen and (max-width: 872px) {
    width: 55%;
    padding:  1.2rem;
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 840px) {
    .MuiTypography-root.MuiTypography-body1{
font-size: 0.9rem;
    }
  }
@media only screen and (max-width: 709px){
  padding: 1.2rem 0.5rem 1.2rem 1.2rem;
  .MuiTypography-root.MuiTypography-body1{
    font-size: 0.8rem;
  }
}
  @media only screen and (max-width: 687px) {
    width: 90%;
    padding: 2.9rem 0 2.9rem 1rem;
  }
  @media only screen and (max-width: 470px) {
    width: 95%;
  }
  @media only screen and (max-width: 366px) {
    padding: 2.9rem 0;
    .MuiTypography-root.MuiTypography-h6{
      font-size: 1rem;
    }
  }
`;

export const SubBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  position: relative;
  width: 85%;
  z-index: 2;
  .ellipse {
    position: absolute;
    right: -4%;
    bottom: 40%;
    z-index: 0;
  }
  .line {
    width: 53px;
    height: 3px;
    background-color: #00d285;
    margin-right: 10px;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 75px;
    text-align: start;
  }

  @media only screen and (max-width: 1417px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 3rem;
    }
  }
  @media only screen and (min-width: 1240px) and (max-width: 1440px) {
    .ellipse {
      right: -10%;
    }
  }
  @media only screen and (max-width: 1266px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2.6rem;
      line-height: 60px;
    }
  }
  @media only screen and (max-width: 1239px) {
    .ellipse {
      right: -15%;
    }
  }
  @media only screen and (max-width: 1080px) {
  }
  @media only screen and (max-width: 1084px) {
    .ellipse {
      right: 10%;
    }
  }
  @media only screen and (max-width: 1039px) {
    .ellipse {
      right: 5%;
    }
  }
  @media only screen and (max-width: 999px) {
    .ellipse {
      right: 0%;
    }
  }
  @media only screen and (max-width: 872px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2rem;
      line-height: 40px;
    }
    .ellipse{
      width: 110px;
      height: 110px;
    }
  }
  @media only screen and (max-width: 773px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }
  @media only screen and (max-width: 687px) {
    .ellipse {
      right: -15%;
      bottom: 25%;
    }
  }
  @media only screen and (max-width: 685px) {
  
    /* .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    } */
  }
  /* @media only screen and (max-width: 587px) {
    .ellipse {
      right: 4%;
    }
  } */
  @media only screen and (max-width: 527px) {
    .ellipse {
      right: 25%;
    }
  }
  @media only screen and (max-width: 470px) {
    .ellipse {
      width: 100px;
      height: 100px;
    }
  }
  @media only screen and (max-width: 367px) {
    .ellipse {
      width: 95px;
      height: 95px;
      right: 17%;
    }
  }
  @media only screen and (max-width: 331px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
    }
  }
`;
