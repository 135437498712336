import React from "react";
import { Typography } from "@mui/material";

import {
  HeaderContainer,
  HeaderLeft,
  HeaderRight,
  SubBox,
} from "./header.style";
import ellipse from "../../../assets/images/ellipse.png";
import partnership from "../../../assets/images/partnership.jpg";
import contact from "../../../assets/images/contact.png";

const Header = () => {
  return (
    <HeaderContainer>
      <img src={contact} alt="contact" className="contact-img" />
      <HeaderLeft>
        <img src={partnership} alt="partnership" className="partnership" />
      </HeaderLeft>
      <HeaderRight>
        <SubBox>
          <div className="line"></div>
          <img src={ellipse} alt="ellipse" className="ellipse" />
          <Typography variant="h4" className="header">EXPERTISE</Typography>
        </SubBox>
        <Typography variant="h6" className="subheader">
          We provide reliable service to our client that is unbeatable
        </Typography>
      </HeaderRight>
    </HeaderContainer>
  );
};

export default Header;
