import React from "react";
import {
  BackBox,
  BoxChild,
  ContactChild,
  CopyrightBox,
  FooterBox,
  FooterBoxChild,
  FooterChild,
  FooterContainer,
  SocialBox,
} from "./footer.style";
import overlay from "../../../assets/images/footer-overlay.png";
import { Typography } from "@mui/material";
import linkedin from "../../../assets/icons/linkedin.png";
import facebook from "../../../assets/icons/facebook.png";
import instagram from "../../../assets/icons/instagram.png";
// import youtube from "../../assets/icons/youtube.png";
import phone from "../../../assets/icons/phone.png";
import email from "../../../assets/icons/email.png";
import logo from "../../../assets/images/logo.png";
import top from "../../../assets/images/back-to-top.png";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  const backToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <FooterContainer>
      <img src={overlay} alt="overlay" className="overlay" />
      <FooterChild>
        <FooterBox className="first">
          <Typography variant="h6">QUICK LINKS</Typography>
          <FooterBoxChild>
            <BoxChild>
              <Typography variant="body1">
                <Link to="/">Home</Link>
              </Typography>
              <Typography variant="body1">
                <Link to="/about">About</Link>
              </Typography>
              <Typography variant="body1">
                <Link to="/product">Portfolio</Link>
              </Typography>
              <Typography variant="body1">
                <Link to="/contact">Contact</Link>
              </Typography>
              <Typography variant="body1">
                <Link to="/blog">Blog</Link>
              </Typography>
              {/* <Typography variant="body1">
                <Link to="#">Our Policy</Link>
              </Typography> */}
              <Typography variant="body1">
                <Link to="/contact">Career</Link>
              </Typography>
            </BoxChild>
          </FooterBoxChild>
        </FooterBox>
        <FooterBox className="second">
          <Typography variant="h6">OUR SERVICES</Typography>
          <Typography variant="body1">
            <Link to="/expertise">Web Development</Link>
          </Typography>
          <Typography variant="body1">
            <Link to="/expertise">Digital Product Design</Link>
          </Typography>
          <Typography variant="body1">
            <Link to="/expertise">Cloud/DevOps</Link>
          </Typography>
          <Typography variant="body1">
            <Link to="/expertise">Mobile Applications</Link>
          </Typography>
          <Typography variant="body1">
            <Link to="/expertise">Dedicated Devlopment</Link>
          </Typography>
        </FooterBox>
        <FooterBox className="third">
          <Typography variant="h6">OUR TECHNOLOGIES</Typography>
          <Typography variant="body1">
            <p>PHP & Laravel</p>
          </Typography>
          <Typography variant="body1">
            <p>React & React Native</p>
          </Typography>
          <Typography variant="body1">
            <Link to="/"></Link>
          </Typography>
        </FooterBox>
        <FooterBox className="fourth">
          <Typography variant="h6">CONTACT & FOLLOW US</Typography>
          <SocialBox>
            <Link to="/">
              <img src={linkedin} alt="linkedin logo" className="social-icon" />
            </Link>
            <Link to="/">
              {" "}
              <img
                src={instagram}
                alt="instagram logo"
                className="social-icon"
              />
            </Link>
            <Link to="/">
              <img src={facebook} alt="facebook logo" className="social-icon" />
            </Link>
            {/* <img src={youtube} alt="youtube logo" className="social-icon" /> */}
            <Link to="/">
              <YouTubeIcon className="social-icon" />
            </Link>
          </SocialBox>
          <ContactChild>
            <img src={phone} alt="phone icon" className="contact-icon" />
            <Typography variant="body1">
              <a href="tel:+2348066071971">(234) 8066071971</a>
            </Typography>
          </ContactChild>
          <ContactChild>
            <img src={email} alt="email icon" className="contact-icon" />
            <Typography variant="body1">
              <a href="mailto:contact@petrongsoftware.com">
                {" "}
                contact@petrongsoftware.com
              </a>
            </Typography>
          </ContactChild>
          <img src={logo} alt="logo" className="logo" />
        </FooterBox>
      </FooterChild>
      <BackBox onClick={backToTop}>
        <img src={top} alt="top icon" className="back-icon" align-center />
      </BackBox>
      <CopyrightBox>
        <Typography variant="body1">
          Petrong Software Solution Copyright {year} - All Right Reserved
        </Typography>
      </CopyrightBox>
    </FooterContainer>
  );
};

export default Footer;
