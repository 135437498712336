import { Typography } from "@mui/material";
import React from "react";
import {
  BodyBottom,
  BodyChild,
  BodyContainer,
  BodyTop,
  BottomLeft,
  BottomRight,
  LeftChild,
  Tech,
  TextContainer,
  Title,
  Topbox,
  TopboxChild,
  Values,
  ValuesBox,
} from "./body.style";
import arrow from "../../../assets/images/arrow.png";
import values from "../../../assets/images/values.png";
import aboutSm from "../../../assets/images/about-sm.png";
import group from "../../../assets/images/group.png";

const Body = () => {
  return (
    <BodyContainer>
      <BodyChild>
        <BodyBottom>
          <BottomLeft>
            <Title>
              <div className="line"></div>
              <Typography variant="h4" className="header">
                OUR CORE VALUES
              </Typography>
            </Title>
            <Typography variant="h4" className="subheader">
              A melting pot for the best idea
            </Typography>
            <LeftChild>
              <img src={values} alt="values" className="values" />
              <ValuesBox>
                <Values>
                  <Typography variant="h6">Integrity</Typography>
                  <Typography variant="body1">
                    We ensure that our products are capable of doing exactly as
                    stated in our specifications and more
                  </Typography>
                </Values>
                <Values>
                  <Typography variant="h6">Innovation</Typography>
                  <Typography variant="body1">
                    To have a great impact towards a digital transformation that
                    improves the standard and quality of living .
                  </Typography>
                </Values>
                <Values>
                  <Typography variant="h6">Excellence</Typography>
                  <Typography variant="body1">
                    To be a 10x company globally, 10 times better than our
                    comparison on our business model, products, services, work
                    ethic, etc.
                  </Typography>
                </Values>
                <Values>
                  <Typography variant="h6">Selflessness</Typography>
                  <Typography variant="body1">
                    To put our customers and target market first, the employees
                    and staff next, and then the management to follow.
                  </Typography>
                </Values>
              </ValuesBox>
            </LeftChild>
          </BottomLeft>
          <BottomRight>
            <img src={group} alt="group" className="groups" />
            <img src={aboutSm} alt="about" className="about" />
          </BottomRight>
        </BodyBottom>
      </BodyChild>
    </BodyContainer>
  );
};

export default Body;
