import styled from "styled-components"
import { Box } from "@mui/material"


export const ContactContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  .contact-navbar {
    background-color: black !important;
  }
  .contact-img {
    position: absolute;
    top: 0%;
    left: 10%;
  }
  .group {
    position: absolute;
    width: 167px;
    height: 151.34px;
    top: 34%;
    right: 7%;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #424242;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #212121;
  }

  @media only screen and (max-width: 1999px) {
    .group {
      top: 36%;
    }
  }
  @media only screen and (max-width: 1084px) {
    .contact-img {
      top: -2%;
      left: 8%;
    }
  }
  @media only screen and (max-width: 946px) {
    .contact-img {
      left: 1%;
    }
  }
  @media only screen and (max-width: 840px) {
    .contact-img {
      top: -5%;
      left: -10%;
    }
    .group {
      top: 29%;
    }
  }
  @media only screen and (max-width: 770px) {
    .contact-img {
      display: none;
    }
  }
  @media only screen and (max-width: 760px) {
    .group {
      top: 26%;
    }
  }
  @media only screen and (max-width: 687px) {
    .group {
      width: 155px;
      height: 140px;
      top: 23%;
    }
  }
  @media only screen and (max-width: 596px) {
    .group {
      width: 145px;
      height: 130px;
      top: 20%;
    }
  }
  @media only screen and (max-width: 480px) {
    .group {
      width: 135px;
      height: 120px;
      /* top: 22%; */
    }
  }
  /* @media only screen and (max-width: 470px) {
    .group {
      top: 23%;
    }
  } */
  @media only screen and (max-width: 380px) {
    .group {
      width: 115px;
      height: 105px;
      /* top: 22%; */
    }
  }
  @media only screen and (max-width: 300px) {
    .group {
      width: 100px;
      height: 90px;
      top: 21%;
    }
  }
`;