import { Typography } from "@mui/material";
import React from "react";
import {
  BodyChild,
  BodyContainer,
  BodyLeft,
  BodyRight,
} from "./body.style";
import partnershipA from "../../../assets/images/partnershipA.png";
import partnershipB from "../../../assets/images/partnershipB.png";
import partnershipC from "../../../assets/images/partnershipC.png";

const Body = () => {
  return (
    <BodyContainer>
      <BodyChild>
        <BodyLeft>
          <Typography variant="body2">01</Typography>
          <Typography variant="h4">
            State your challenge, we come with possible solutions.
          </Typography>
          <Typography variant="body1">
            Your challenges require unique approaches. Maybe you need a trusted
            team to handle your many ongoing projects. Or look for a jelled team
            with multi-expertise to take care of your complex project. Perhaps,
            you search for a dedicated professional to maintain your project on
            daily basis, or you have a whole ‘nother kind of problem. Whatever
            your challenge is, we are here to find a working solution.
          </Typography>
        </BodyLeft>

        <BodyRight>
          <img src={partnershipA} alt="partnershipA" className="expertiseA" />
        </BodyRight>
      </BodyChild>
      <BodyChild>
         {/* <Link to="/wa.link/m11jc0"><img src={chat} alt="chat" className="chat" /></Link> */}
        <BodyRight>
          <img src={partnershipB} alt="partnershipB" className="expertiseB" />
        </BodyRight>
        <BodyLeft>
          <Typography variant="body2">02</Typography>
          <Typography variant="h4">Pilot Project</Typography>
          <Typography variant="body1" className="second">
            We’ll give you the opportunity to evaluate our work via a pilot
            project and see if we are the right partner for your business. A
            pilot project could be the coding of 1 homepage, a specific feature,
            or just the progress we’ve made for 8 working hours. This way you’ll
            get an idea about the quality of our work, the communication
            process, and everything else that is essential to you.
          </Typography>
          {/* <Button variant="contained">Read More</Button> */}
        </BodyLeft>
      </BodyChild>
      <BodyChild>
        <BodyLeft>
          <Typography variant="body2">03</Typography>
          <Typography variant="h4">Collaboration and bonding</Typography>
          <Typography variant="body1">
            Trust and smooth workflow are of paramount importance to us and to
            you, we believe Once we start collaborating, both our teams will
            spend some time jelling and adjusting in order to develop a strong
            and healthy B2B relationship.
          </Typography>

          {/* <Button variant="contained">Read More</Button> */}
        </BodyLeft>
        <BodyRight>
          <img src={partnershipC} alt="partnershipC" className="expertiseC" />
        </BodyRight>
      </BodyChild>
    
    </BodyContainer>
  );
};

export default Body;
