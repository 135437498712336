import { Button, Typography } from "@mui/material";
import React from "react";
import { BodyContainer, BodyContent, ContentChild, Title } from "./body.style";
import blogA from "../../../assets/images/blogA.png";
import blogB from "../../../assets/images/blogB.png";
import { Link } from "react-router-dom";
const Body = () => {
  return (
    <BodyContainer>
      <Title>
        <Typography variant="body1">Web Development</Typography>
        <Button variant="contained">See more articles</Button>
      </Title>
      <BodyContent>
        <ContentChild>
          <img src={blogB} alt="blogB" />
          <Typography variant="h6">
            How To Design The Perfect Hero Image: Guide, Practical Tips and
            Examples
          </Typography>
          <Typography variant="body1">
            Design it an important and visual illustration that revolve in our
            daly lives, we dont run from it we utilise and make use of it.
          </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
        </ContentChild>
        <ContentChild>
          <img src={blogB} alt="blogB" />
          <Typography variant="h6">
            How To Design The Perfect Hero Image: Guide, Practical Tips and
            Examples
          </Typography>
          <Typography variant="body1">
            Design it an important and visual illustration that revolve in our
            daly lives, we dont run from it we utilise and make use of it.
          </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
        </ContentChild>
        <ContentChild>
          <img src={blogA} alt="blogA" />
          <Typography variant="h6">
            How To Design The Perfect Hero Image: Guide, Practical Tips and
            Examples
          </Typography>
          <Typography variant="body1">
            Design it an important and visual illustration that revolve in our
            daly lives, we dont run from it we utilise and make use of it.
          </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
        </ContentChild>
      </BodyContent>
    </BodyContainer>
  );
};

export default Body;
