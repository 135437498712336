import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import chat from "../../assets/images/chat.png";
import About from "../../components/home-components/about-section/About";
import Core from "../../components/home-components/core-section/Core";
import Footer from "../../components/shared-components/footer/Footer";
import Header from "../../components/home-components/header/Header";
import Partnership from "../../components/home-components/partnership-section/Partnership";
import Promises from "../../components/home-components/Promise-section/Promises";
import Subfooter from "../../components/shared-components/subfooter/Subfooter";
import Testimonial from "../../components/home-components/testimonial-section/Testimonial";

import { HomeContainer } from "./home.style";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <HomeContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Petrong Software Solutions - Home" />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="Petrong, Petrong Software, Petrong Software Solutions"
        />
        <title>Petrong - Home</title>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
        />
      </Helmet>
      <Header />
      <a href=" https://wa.me/2348066071971" target="_blank" rel="noreferrer">
        <img src={chat} alt="chat" className="chat" />
      </a>
      <Core />
      <Partnership />
      <Testimonial />
      <About />
      <Promises />
      <Subfooter />
      <Footer />
    </HomeContainer>
  );
};

export default Home;
