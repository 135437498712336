import { Typography } from "@mui/material";
import React from "react";
import {
  PromiseItem,
  PromiseChild,
  PromiseContainer,
  Text,
  Title,
} from "./promise.style";
import programming from "../../../assets/icons/Programming.png";
import responsive from "../../../assets/icons/Responsive Device.png";
import team from "../../../assets/icons/team.png";
import support from "../../../assets/icons/Support.png";
import turn from "../../../assets/icons/U Turn.png";
import secure from "../../../assets/icons/Secure.png";
const Promises = () => {
  return (
    <PromiseContainer>
      <Title>
        <Typography variant="h4">Our Promise</Typography>
        <Typography variant="body1">
          As part of our high quality service, we'd like to offer something
          extra too.
        </Typography>
      </Title>
      <PromiseChild>
        <PromiseItem>
          <img src={programming} alt="programming" />
          <Text>
            <Typography variant="h6">Top Code Quality</Typography>
            <Typography variant="body1">
              What we promise is not simply code but rather a solution. You get
              a W3C fully compliant markup with no compromise on quality.
            </Typography>
          </Text>
        </PromiseItem>
        <PromiseItem>
          <img src={responsive} alt="responsive" />
          <Text>
            <Typography variant="h6">Responsive</Typography>
            <Typography variant="body1">
              We know you look for positive attitude, timely reactions, and
              flexibility. We are here to listen and give the utmost of our
              capabilities.
            </Typography>
          </Text>
        </PromiseItem>
        <PromiseItem>
          <img src={team} alt="team" />
          <Text>
            <Typography variant="h6"> Rockstar Team</Typography>
            <Typography variant="body1">
              Expertise is our USP. You get an all-star squad of experienced
              geeks, fully at your service and armed with coding skills to die
              for.
            </Typography>
          </Text>
        </PromiseItem>
        <PromiseItem>
          <img src={turn} alt="turn" />
          <Text>
            <Typography variant="h6">Fast Turn-arounds</Typography>
            <Typography variant="body1">
              Speed matters no less than quality. We are fast, agile and capable
              of delivering top notch quality code in a timeframe chosen by you.
            </Typography>
          </Text>
        </PromiseItem>
        <PromiseItem>
          <img src={support} alt="support" />
          <Text>
            <Typography variant="h6">Long-time Support</Typography>
            <Typography variant="body1">
              The ultimate proof of our code quality : you get our non-expiring
              support. If any bugs happen to appear in the future, just write us
              a line.
            </Typography>
          </Text>
        </PromiseItem>
        <PromiseItem>
          <img src={secure} alt="secure" />
          <Text>
            <Typography variant="h6">Secured Agreement</Typography>
            <Typography variant="body1">
              Your artwork is fully yours and we would never claim otherwise.
              You get our word and our full willingness to sign an NDA.
            </Typography>
          </Text>
        </PromiseItem>
      </PromiseChild>
    </PromiseContainer>
  );
};

export default Promises;
