import styled from "styled-components";
import { Box } from "@mui/material";

export const BodyContainer = styled(Box)`
  width: 100%;
  /* height: 220vh; */
  background: #fcfcfc;
  box-sizing: border-box;
  padding: 3rem 1rem;
`;

export const BodyChild = styled(Box)`
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 2rem;
  position: relative;

  .chat {
    position: absolute;
    top: 14%;
    right: -5%;
  }
  @media only screen and (max-width: 1094px) {
    width: 90%;
    .chat {
      top: 8%;
      right: 1%;
    }
  }
  @media only screen and (max-width: 831px) {
    width: 100%;
    padding-left: 01.5rem;
    .chat {
      right: 5%;
    }
  }
  @media only screen and (max-width: 450px) {
    .chat {
      width: 12%;
    }
  }
  @media only screen and (max-width: 388px) {
    padding-left: 0;
    .chat {
      top: 4%;
    }
  }
  @media only screen and (max-width: 312px) {
    .chat {
      top: 0%;
    }
  }
`;

export const BodyTop = styled(Box)`
  width: 100%;
`;
export const Title = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .line {
    width: 53px;
    height: 3px;
    background-color: #00d285;
    margin-right: 10px;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 685px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }
`;
export const TextContainer = styled(Box)`
  width: 45%;

  .MuiTypography-root.MuiTypography-h4 {
    margin-bottom: 1.5rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 25px;
    color: #000d2f;
  }

  @media only screen and (max-width: 976px) {
    width: 50%;
  }
  @media only screen and (max-width: 743px) {
    width: 60%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }
  @media only screen and (max-width: 645px) {
    width: 70%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.6rem;
    }
  }
  @media only screen and (max-width: 579px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.4rem;
    }
  }
  @media only screen and (max-width: 514px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 448px) {
    width: 85%;
  }
  @media only screen and (max-width: 312px) {
    width: 95%;
  }
`;

export const Topbox = styled(Box)`
  width: 100%;
  height: 65vh;
  margin-top: 15rem;
  background: #f2f5fc;
  position: relative;
  @media only screen and (max-width: 496px){
    height: 50vh;
  }
`;

export const TopboxChild = styled(Box)`
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  top: -30%;
  right: 7%;
  @media only screen and (max-width: 566px){
    width: 70%;
  }
  @media only screen and (max-width: 496px){
    width: 80%;
  }
  @media only screen and (max-width: 415px){
    width: 90%;
  }
  @media only screen and (max-width: 380px){
    width: 100%;
  }
`;

export const Tech = styled(Box)`
  width: 48%;
  height: 200px;
  background: #ebfaf4;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 1rem;
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Monteserrat", sans-serif;
    font-size: 1.2rem;
    margin-top: 2rem;
    color: #c4c4c4;
  }
  .MuiTypography-root.MuiTypography-h6 {
    margin: 2rem 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    font-family: "Poppins", sans-serif;
  }

  @media only screen and (max-width: 566px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 0.9rem;
    }
  }
`;

export const BodyBottom = styled(Box)`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const BottomLeft = styled(Box)`
  width: 50%;
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 45px;
    color: #000d2f;
    margin: 1rem 0 1rem;
  }
  @media only screen and (max-width: 852px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.8rem;
    }
  }

  @media only screen and (max-width: 587px) {
    width: 90%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.5rem;
    }
  }
  @media only screen and (max-width: 468px) {
    width: 97%;
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.3rem;
    }
  }
  @media only screen and (max-width: 388px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 339px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 288px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 0.9rem;
    }
  }
`;

export const LeftChild = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  img {
    margin-top: -1rem;
  }
  @media only screen and (max-width: 468px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (max-width: 340px) {
    img {
      width: 29%;
      height: 480px;
    }
  }
`;
export const ValuesBox = styled(Box)`
  margin-left: 1.5rem;
  margin-top: 2rem;
`;

export const Values = styled(Box)`
  margin-bottom: 3rem;
  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 0.7rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Monteserrat", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 20px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.8);
  }
  @media only screen and (max-width: 852px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1.2rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.8rem;
    }
  }
`;
export const BottomRight = styled(Box)`
  width: 50%;
  text-align: right;
  position: relative;
  .groups {
    position: absolute;
    width: 150px;
    height: 141.34px;
    top: -12%;
    left: 35%;
  }
  @media only screen and (max-width: 852px) {
    img {
      width: 80%;
    }
    .groups {
      left: 1%;
    }
  }
  @media only screen and (max-width: 587px) {
    display: none;
  }
`;
