import styled from "styled-components";
import { Box } from "@mui/material";

export const HomeContainer = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  .chat {
    position: fixed;
    right: 7%;
    bottom: 2%;
    z-index: 6;
    @media only screen and (max-width: 420px) {
      width: 15%;
    }
  }
`;
