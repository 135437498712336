import { Typography } from "@mui/material";
import React from "react";
// import { Link } from "react-router-dom";
import flexiblebase from "../../../assets/images/flexiblebase.png";
import fullrange from "../../../assets/images/fullrange.png";
import kephren from "../../../assets/images/kephren.png";
import trenko from "../../../assets/images/trenko.png";
import fastcredit from "../../../assets/images/clientA.png";
import chronicles from "../../../assets/images/clientC.png";
import creditcapital from "../../../assets/images/clientD.jpg";
import fti from "../../../assets/images/fti.png";
import dad_logo from "../../../assets/images/dad_logo.png";
import bridge_credit from "../../../assets/images/bridge-credit-nigeria.jpg";
import sja from '../../../assets/images/sja_logo_correct_sized1.webp'
import kasnoi from '../../../assets/images/logos.png'
import barrister from '../../../assets/images/Capture.PNG'
import autospark from '../../../assets/images/1-autosparkle-waterless-detailing-logo.png'
import greenelectric from '../../../assets/images/logoo.png'
import glukogest from "../../../assets/images/gluko.PNG"
import carlsbury from '../../../assets/images/logo (1).png'
import firstpoint from '../../../assets/images/main-logo-wide.png'
import chrisfloourish from '../../../assets/images/358700907_589295466739190_8325090093364688520_n.jpg'
import sparklescake from '../../../assets/images/27624868_2254505031442858_3201590747815551248_o.jpg'
import hogwin from '../../../assets/images/imageedit_1_9010804412.jpg'

import { BodyContainer, BodyContent, LinkBox, TextBox } from "./body.style";

const Body = () => {
  return (
    <BodyContainer>
      <BodyContent>
        <TextBox>
          <Typography variant="h4">Web & Mobile Applications</Typography>
          <Typography variant="body1">
            We help create solutions that optimize processes, improve
            accessibility to data, and increase successful.
          </Typography>
        </TextBox>
        <LinkBox id="projects">
           <a href="https://goofy-sinoussi-82a245.netlify.app/" target="_blank" rel="noreferrer">
            <img src={flexiblebase} alt="flexiblebase" />
          </a>
          <a href="https://fullrange.petrongsoftware.com" target="_blank" rel="noreferrer">
            <img src={fullrange} alt="fullrange" />
          </a>
          <a href="https://www.kephrenng.com" target="_blank" rel="noreferrer">
            <img src={kephren} alt="kephren" />
          </a>
          <a href="https://www.trenko.com" target="_blank" rel="noreferrer">
            <img src={trenko} alt="trenko" style={{transform: 'scale(1.7)'}}/>
          </a>
          <a href="https://www.sja.org.uk/" target="_blank" rel="noreferrer">
            <img src={sja} alt="Saint John Ambulance" />
          </a>
          <a href="https://kasnoiluxuryhomes.com.ng/" target="_blank" rel="noreferrer">
            <img src={kasnoi} alt="Kasnoi" />
          </a>
          <a href="https://ezenwaandassociates.com.ng/" target="_blank" rel="noreferrer">
            <img src={barrister} alt="barrister" style={{transform: 'scale(2)'}}/>
          </a>
          <a href="https://myautosparkle.com/" target="_blank" rel="noreferrer">
            <img src={autospark} alt="myautosparkle" style={{transform: 'scale(0.6)'}}/>
          </a>
          <a href="https://greenelectricsolutions.com.ng/" target="_blank" rel="noreferrer">
            <img src={greenelectric} alt="greenelectric" style={{transform: 'scale(1.5)'}}/>
          </a>
          <a href="https://glukogest.com/" target="_blank" rel="noreferrer">
            <img src={glukogest} alt="glukogest" style={{transform: 'scale(1.5)'}}/>
          </a>
          {/* <a href="https://carlsburywinery.com/" target="_blank" rel="noreferrer">
            <img src={carlsbury} alt="carlsbury" style={{transform: 'scale(1.5)'}}/>
          </a> */}
          {/* <a href="https://firstpointconnection.com/" target="_blank" rel="noreferrer">
            <img src={firstpoint} alt="firstpoint" style={{transform: 'scale(1.5)'}}/>
          </a>
           <a href="#projects">
            <img src={fastcredit} alt="fastcredit"  style={{transform: 'scale(1.4)'}}/>
          </a> */}
          <a href="#projects">
            <img src={chronicles} alt="chronicles" />
          </a>
          <a href="#projects">
            <img src={sparklescake} alt="sparklescake" />
          </a>
          <a href="#projects">
            <img src={chrisfloourish} alt="chrisfloourish" />
          </a>
          <a href="https://hogwin.org/about-us/">
            <img src={hogwin} alt="chrisfloourish"  style={{transform: 'scale(1.4)'}} />
          </a>
          {/* <a href="#projects">
            <img src={creditcapital} alt="creditcapital" />
          </a>
          <a href="#projects">
            <img src={bridge_credit} alt="bridge-credit" />
          </a>
           <a href="#projects">
            <img src={fti} alt="fti"  style={{transform: 'scale(0.7)'}}/>
          </a> */}
          <a href="#projects">
            <img src={dad_logo} alt="dad"  style={{transform: 'scale(0.9)'}}/>
          </a>
        </LinkBox>
      </BodyContent>
    </BodyContainer>
  );
};

export default Body;
