import { Button, Typography } from "@mui/material";
import React from "react";
import { SubContainer, SubLeft, SubRight, TextBox, TextBoxB, Title } from "./sub.style";
import blogPc from "../../../assets/images/blogPC.png";
import { Link } from "react-router-dom";

const Sub = () => {
  return (
    <SubContainer>
      <SubLeft>
        <Title>
          <Typography variant="h6">Digital Marketing</Typography>
          <div className="line"></div>
        </Title>
        <TextBox>
          <Typography variant="h6">
            How To Design The Perfect Hero Image: Guide, Practical Tips and
            Examples
          </Typography>
          <Typography variant="body1">
            Design it an important and visual illustration that revolve in our
            daly lives, we dont run from it we utilise and make use of it.
          </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
        </TextBox>
        <TextBox>
          <Typography variant="h6">
            How To Design The Perfect Hero Image: Guide, Practical Tips and
            Examples
          </Typography>
          <Typography variant="body1">
            Design it an important and visual illustration that revolve in our
            daly lives, we dont run from it we utilise and make use of it.
          </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
        </TextBox>
        <TextBox>
          <Typography variant="h6">
            How To Design The Perfect Hero Image: Guide, Practical Tips and
            Examples
          </Typography>
          <Typography variant="body1">
            Design it an important and visual illustration that revolve in our
            daly lives, we dont run from it we utilise and make use of it.
          </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
        </TextBox>
      </SubLeft>
      <SubRight>
          <img src={blogPc} alt="blog-pc" />
            <TextBoxB>
          <Typography variant="h6">
            Top five digital development in 2022
          </Typography>
          <Typography variant="body1">
         Design it an important and visual illustration that revolve in our daly lives, we dont run from it we utilise and make use of it.
          </Typography>
          <Link to="/blog/post"><Button variant="contained">Read more</Button></Link>
        </TextBoxB>
      </SubRight>
    </SubContainer>
  );
};

export default Sub;
