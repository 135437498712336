import styled from "styled-components"
import { Box} from "@mui/material"

export const Corecontainer = styled(Box)`
width: 100%;
box-sizing: border-box;
background-color: #ffffff;
margin: 1rem 0 0;
position: relative;
z-index: 1;

`

export const CoreContainerChild = styled(Box)`
  width: 90%;
  margin: 1rem auto;
  height: 100%;
  display: flex;

  justify-content: space-between;
  @media only screen and (max-width: 1240px) {
    width: 92%;
  }
  @media only screen and (max-width: 970px) {
    width: 92%;
    align-items: center;
  }
  @media only screen and (max-width: 743px){
      justify-content: center;
  }
`;
export const CoreBoxA = styled(Box)`
  width: 47%;
  box-sizing: border-box;
  padding-left: 2rem;
  padding-top: 4rem;

  .core-image {
    /* object-fit: contain; */
    width: 100%;
    height: 70vh;
  }
  @media only screen and (max-width: 1240px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 970px){
      width: 44%;
      padding-top: 0;
  }
  @media only screen and (max-width: 799px) {
      width: 40%;
      .core-image{
          height: 55vh;
      }
  }
  @media only screen and (max-width: 743px) {
      display: none;
  }
`;

export const CoreBoxB = styled(Box)`
  width: 47%;
  height: 140%;
  .MuiTypography-root.MuiTypography-h4 {
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 54px;
    color: #000d2f;
    margin-bottom: 1.2rem;
    font-family: "Poppins", sans-serif;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 32px;
    color: #000d2f;
    margin-bottom: 1rem;
    text-align: justify;
    font-family: "Monteserrat", sans-serif;
  }
  @media only screen and (max-width: 1220px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2.2rem;
      margin-bottom: 2rem;
      line-height: 45px;
    }
    .MuiTypography-root.MuiTypography-body1 {
      line-height: 28px;
      margin-bottom: 0.8rem;
    }
  }
  @media only screen and (max-width: 999px) {
    width: 51%;
  }
  @media only screen and (max-width: 880px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 2.05rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.9rem;
    }
  }
  @media only screen and (max-width: 799px) {
    width: 55%;
  }

  @media only screen and (max-width: 743px) {
    width: 90%;
  }
`;

export const ServiceBox = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;

  img{
    width:clamp(2.4375rem, 1.2133rem + 5.5963vw, 6.25rem);
    height:clamp(2.4375rem, 1.2133rem + 5.5963vw, 6.25rem);
    border-radius:50%
  }
`

export const TextBox = styled(Box)`
  display: block;
  margin-left: 1.5rem;
  .MuiTypography-root.MuiTypography-h6 {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 21px;
    color: #000d2f;
    margin-bottom: 0.8rem;
    font-family: "Poppins", sans-serif;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-size: 1.2rem;
    text-align: justify;
    color: #000d2f;
  }
  @media only screen and (max-width: 880px) {
    .MuiTypography-root.MuiTypography-h6 {
      font-size: 1rem;
      margin-bottom: 0.8rem;
    }
    .MuiTypography-root.MuiTypography-body1 {
      font-size: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }
`;