import { Button, Typography } from "@mui/material";
import React from "react";
import {
  BodyChild,
  BodyContainer,
  BodyLeft,
  BodyRight,
  Extra,
} from "./body.style";
import paciolli from "../../../assets/images/pacioli.png";
import kollegio from "../../../assets/images/kollegio.png";
import startupclerk from "../../../assets/images/startupclerk.png";
import { Link } from "react-router-dom";
const Body = () => {
  return (
    <BodyContainer>
      <BodyChild>
        <BodyLeft>
          <Typography variant="body2">01</Typography>
          <Typography variant="h4">Pacioli</Typography>
          <Typography variant="body1">
            Everyday 60% of Traditional Microfinance institutions in Africa
            dispatch their sales team, with printed hard copy forms in hand; The
            result: stress, unprecedented losses, high error rates, poor
            turn-around-time, and an average number of 500 new customers
            on-boarded in 30 days. These are the problems we solve for the
            traditional Microfinance Institutions in Africa that are struggling
            to utilize manual processes or other inefficient means to facilitate
            distribution of financial services to the underserved. <br />
            Our solution is a tailor-made digital lending tool that helps
            Microfinance Institutions in Africa on-board over 2,000 customers in
            24hrs, with 10x faster turn-around-time, more security and automated
            customer data validation. It assists to quickly extend financial
            services to customers who may not be tech savy but would like to
            request credit and do repayments when due. <br />
            After launching in 2020, We have been trusted by Microfinance
            Institutions across Nigeria to process transactions of over 40,000
            of their customers requests, valued at over $10,000,000.
          </Typography>

          {/* <Button variant="contained">Read More</Button> */}
        </BodyLeft>

        <BodyRight>
          <img src={paciolli} alt="expertiseA" className="expertiseA" />
        </BodyRight>
      </BodyChild>
      <BodyChild>
        <BodyRight>
          <img src={kollegio} alt="expertiseB" className="expertiseB" />
        </BodyRight>
        <BodyLeft>
          <Typography variant="body2">02</Typography>
          <Typography variant="h4">Kollegio</Typography>
          <Typography variant="body1" className="second">
            The software is a highly flexible and robust Learning Management
            System (LMS) which will fit in well for any kind of training,
            academic or professional. It so dynamic that an organization can use
            the software for an academic, professional, vocational, and more
            purposes concurrently. As it has been elaborately spelt out in this
            list, the built-in features enable the organization to set up live
            video classrooms, computer based test system, video lesson uploads,
            ebooks in form of PDFs, and more. There are also other admin
            features like bulk email to users, audit trail, permissions and
            access control, etc. The software is heavily loaded, and it is rare
            to find its match in the Nigerian Edtech ecosystem, lots of efforts,
            resources and energy have also been put in to ensure its
            realization, prospects cannot wait to enjoy from the profit that the
            software promises.
          </Typography>
          {/* <Button variant="contained">Read More</Button> */}
        </BodyLeft>
      </BodyChild>
      <BodyChild style={{display: 'none'}}>
        <BodyLeft>
          <Typography variant="body2">03</Typography>
          <Typography variant="h4">Startupclerk</Typography>
          <Typography variant="body1">
            StartupClerk is an account management software designed to help
            small businesses do all the book keeping that is necessary to enable
            their businesses evade financial crisis, gain better credibility to
            investors and ensure excellent cashflow management, without the
            presence of an accountant; thus allowing them the space to focus
            their attention on product development, marketing and other
            profitable business ventures.
          </Typography>

          <Button variant="contained">Read More</Button>.
        </BodyLeft>
        <BodyRight>
          <img src={startupclerk} alt="expertiseC" className="expertiseC" />
        </BodyRight>
      </BodyChild>
      <Extra>
        <Typography variant="h6">Let's build great things together!</Typography>
        <Typography variant="body1">
          Fill out this form and one of our client success managers will contact
          you within 24 hours. We have notifications set to make sure your
          message is received.
        </Typography>
        <Link to="/contact">
          <Button variant="contained">Contact</Button>
        </Link>
      </Extra>
    </BodyContainer>
  );
};

export default Body;
