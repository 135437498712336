import { Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Bottom, Inputbox, SubChild,
  SubContainer, SubLeft, SubRight,
  TextBox, Title, Top,
} from "./sub.style";
import line from "../../../assets/icons/line.png";
import visit from "../../../assets/icons/vist.png";

const Sub = () => {

  const [values, setValues] = useState({
    name: '',
    email: '',
    what: '',
    how: '',
    instruct: ''
  })

  const valueChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const Mail = ()=> {
    let Subject = "Project Qoute".replace(" ", "%20");
    let Draft = "Name: "
        +values.name.replace(/[^a-zA-Z0-9 ]/g, '')
        +"%0A Reply to: "
        +values.email.replace(/[^a-zA-Z0-9@. ]/g, '')
        +"%0A%0A What project are you embarking on?: %0A"
        +values.what.replace(/[^a-zA-Z0-9.,?:'()!+ ]/g, ' ')
        +"%0A%0A How soon do you want it done?: %0A"
        +values.how.replace(/[^a-zA-Z0-9.,?:'()!+ ]/g, ' ')
        +"%0A%0A Instructions: %0A"
        +values.instruct.replace(/[^a-zA-Z0-9.,?:'()!+ ]/g, ' ')
        +"%0A%0A Regards."
    let Message = Draft.replace(" ", "%20")
        .replace(".","%2E").replace(",","%2C")
        .replace("?","%3F").replace("(","%28")
        .replace(")","%28").replace("'","%27")
        .replace("!","%21").replace("+","%2B")
    let mail = "mailto:contact@petrongsoftware.com?subject="+Subject+"&body="+Message
    window.location.replace(mail)
  }

  return (
    <SubContainer>
      <SubChild>
        <SubLeft>
          <Title>
            <div className="line"></div>
            <Typography variant="h4" className="header">WORK WITH US</Typography>
          </Title>
          <Typography variant="h6" className="subheader">
            Request a Quote
          </Typography>
          <Inputbox>
            <Typography variant="body1">Name</Typography>
            <TextField variant="outlined" name="name" onChange={valueChange}/>
          </Inputbox>
          <Inputbox>
            <Typography variant="body1">Email Address</Typography>
            <TextField variant="outlined" type="email" name="email" onChange={valueChange}/>
          </Inputbox>
          <Inputbox>
            <Typography variant="body1">What project are you embarking on?</Typography>
            <TextField variant="outlined" name="what" onChange={valueChange} />
          </Inputbox>
          <Inputbox>
            <Typography variant="body1">How soon do you want it done?</Typography>
            <TextField variant="outlined" name="how" onChange={valueChange} />
          </Inputbox>
          <Inputbox>
            <Typography variant="body1">Instructions</Typography>
            <TextField variant="outlined" multiline rows={3} name="instruct" onChange={valueChange}/>
          </Inputbox>
          {/* <Typography variant="body2">Need to fill marked. Make a call for more details required</Typography> */}
          <Button variant="contained" onClick={()=>Mail()}>Submit</Button>
        </SubLeft>
        <SubRight>
          <Top>
            <img src={visit} alt="visit" />
            <TextBox>
              <Typography variant="h6">Pay us a visit</Typography>
              <Typography variant="body1">
                26, Fadare Street, Ogba, Lagos, Nigeria.
              </Typography>
            </TextBox>
          </Top>
          <Bottom>
            <img src={line} alt="line" className="line" />
            <TextBox>
               <Typography variant="h6">Get in touch with us</Typography>
              <Typography variant="body1">
               contact@petrongsoftware.com <br /> +234 806 6071 971
              </Typography>
            </TextBox>
          </Bottom>
        </SubRight>
      </SubChild>
    </SubContainer>
  );
};

export default Sub;
