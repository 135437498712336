import styled from "styled-components";
import { AppBar, Box, Button, Toolbar } from "@mui/material";

export const MenuBox = styled(Box)`
  width: 100%;
  height: 10vh;
  margin: 0 auto;
  padding: 0;
  top: 0;
  z-index: 150;
  box-shadow: none;

  .MuiPaper-root.MuiAppBar-root {
    background-color: white !important;
    box-shadow: 0px 5px 10px -10px #111;

    padding: 1.5rem 9rem 0;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 1240px) and (min-width: 900px) {
    .MuiPaper-root.MuiAppBar-root {
      padding: 1.5rem 1rem 0.5rem !important;
    }
  }
  @media only screen and (min-width: 769px) {
    .MuiPaper-root.MuiAppBar-root {
      padding: 1.5rem 2.5rem 0.5rem;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    .MuiPaper-root.MuiAppBar-root {
      padding: 1.5rem 2.5rem 0.5rem;
    }
  }
  @media only screen and (max-width: 480px) {
    height: 17vh;
    .MuiPaper-root.MuiAppBar-root {
      padding: 1.5rem 1rem;
    }
  }
`;
export const MenuBar = styled(AppBar)`
  width: 100%;
  color: #000000;
`;

export const MenuToolBar = styled(Toolbar)`
  display: flex;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 7rem !important;
  padding-right: 6rem !important;
  justify-content: space-between;
  @media only screen and (max-width: 1064px) {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  @media only screen and (max-width: 900px){
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;
export const ButtonBox = styled(Box)`
  width: 65%;
  box-sizing: border-box;
  padding-left: 2rem;
  display: flex;
  margin-right: 2rem;
  align-items: center;
  justify-content: space-between;
  .MuiButton-root.MuiButton-text {
    color: #000000;
    text-transform: none;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 21px;
    color: rgba(0, 13, 47, 0.7);
    &:hover {
        border-bottom: 2px solid #00d285;
    }
  }

  @media only screen and (max-width: 1218px) {
    padding-left: 0;
  }

  @media only screen and (max-width: 1169px){
    .MuiButton-root.MuiButton-text{
      font-size: 0.8rem;
    }
  }

  @media only screen and (max-width: 1064px) {
    margin-right: 0;
  }

  @media only screen and (max-width: 984px) {
   width: 73%;
  }
  @media only screen and (max-width: 900px) {
   display: none
  }
`;
export const NavButton = styled(Button)`
  outline: none;
  border: none;
  border-radius: 4px;
  .resources {
    color: black;
  }
`;


export const SearchBox = styled.form`
  width: 25%;
  display: flex;
  .MuiFormControl-root.MuiTextField-root {
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    .MuiInputBase-root.MuiOutlinedInput-root {
      background: transparent;
      border: 1px solid #ebfaf4;
      outline: none;
      .MuiInputBase-input.MuiOutlinedInput-input {
        height: 15px;
        border: none;
        outline: none;
        border-radius: 5px;
      }
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid transparent !important;
      }
    }
  }
  .MuiButton-root.MuiButton-contained {
    background: #000d2f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0;
    .MuiSvgIcon-root {
      color: #00d285;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  @media only screen and (max-width: 900px){
    width: 50%;
  }
  @media only screen and (max-width: 587px){
    width: 65%;
  }
  @media only screen and (max-width: 414px){
    width: 80%;
    .MuiButton-root.MuiButton-contained{
      min-width: 40px;
      .MuiSvgIcon-root{
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
`;