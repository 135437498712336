import styled from "styled-components";
import { Box } from "@mui/material";

export const BodyContainer = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  padding: 4rem;
  @media only screen and (max-width: 860px){
      padding: 4rem 2rem;
  }
  @media only screen and (max-width: 415px){
      padding: 3rem 1rem;
  }
`;

export const Title = styled(Box)`
  width: 92%;
  margin-bottom: 2rem;
  .MuiTypography-root.MuiTypography-h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 64px;
    color: #000d2f;
  }
  @media only screen and (max-width: 989px){
      width: 98%;
     .MuiTypography-root.MuiTypography-h2{
        font-size: 2.4rem;
     }
 }
  @media only screen and (max-width: 820px){
      width: 98%;
     .MuiTypography-root.MuiTypography-h2{
        font-size: 2rem;
     }
 }
  @media only screen and (max-width: 641px){
      width: 98%;
     .MuiTypography-root.MuiTypography-h2{
        font-size: 1.5rem;
     }
 }
  @media only screen and (max-width: 497px){
      width: 100%;
     .MuiTypography-root.MuiTypography-h2{
        font-size: 1.2rem;
     }
 }
  @media only screen and (max-width: 403px){
      width: 100%;
     .MuiTypography-root.MuiTypography-h2{
        font-size: 1rem;
     }
 }
`;
export const LinkBox = styled(Box)`
  display: flex;
  margin-bottom: 2rem;
  .MuiButton-root.MuiButton-text {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
    text-transform: none;
    color: #000d2f;
  }
 
 
`;

export const BodyContent = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  @media only screen and (max-width: 1199px){
      align-items: flex-start;
  }
  @media only screen and (max-width: 768px){
      flex-wrap: wrap;
      justify-content: center;
  }
`;

export const ContentLeft = styled(Box)`
  width: 65%;
  img {
    width: 100%;
    margin-bottom: 3rem;
  }
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 27px;
    color: #000d2f;
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
  @media only screen and (max-width: 620px) {
    width: 100%;
  }
`;
export const Info = styled(Box)`
  display: flex;
  align-items: center;
  .MuiTypography-root.MuiTypography-body1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000d2f;
    margin-left: 20px;
  }
 
`;
export const ContentRight = styled(Box)`
  width: 30%;
  @media only screen and (max-width: 812px) {
    width: 33%;
  }
  @media only screen and (max-width: 768px) {
    width: 90%;
    display: flex;
  }
  @media only screen and (max-width: 620px) {
    width: 100%;
  }
  @media only screen and (max-width: 525px){
      flex-wrap: wrap;
  }
`;
export const Post = styled(Box)`
  width: 100%;
  border-radius: 10px;
  margin-bottom: 2rem;
  .blog {
    width: 100%;
  }
  @media only screen and (max-width: 768px){
      width: 40%;
      margin-right: 2rem;
      
  }
  @media only screen and (max-width: 647px){
      width: 48%;
      margin-right: 2rem;
      &:last-of-type{
          margin-right: 0;
      }
  }
  @media only screen and (max-width: 525px){
      width: 100%;
      margin-right: 0;
    
  }
`;
export const TextBox = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  background: rgba(229, 234, 244, 0.5);
  .MuiTypography-root.MuiTypography-body2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
    text-transform: none;
    color: #000d2f;
    margin-bottom: 1rem;
  }
  .MuiTypography-root.MuiTypography-h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 35px;
    color: #000d2f;
    margin-bottom: 1.5rem;
  }

  @media only screen and (max-width: 1440px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 1199px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 1rem;
      line-height: 40px;
    }
  }
  @media only screen and (max-width: 1037px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 812px) {
    .MuiTypography-root.MuiTypography-h4 {
      font-size: 0.7rem;
    }
  }
  @media only screen and (max-width: 768px) {
    min-height: 35vh;
  }
`;